import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

export default class Transition extends React.Component {

  render() {
    return <CSSTransition {...this.props} />;
  }

}

Transition.propTypes = {
  in: PropTypes.bool,
  appear: PropTypes.bool,
  timeout: PropTypes.number,
  sequence: PropTypes.bool,
};

Transition.defaultProps = {
  in: true,
  timeout: 0,
  appear: true,
};
