import React from 'react';
import { defer } from 'lodash';
import { Helmet } from 'react-helmet-async';

import { Component } from 'common/helpers';

import { trackPage } from 'utils/analytics';
import { APP_NAME } from 'utils/env';

// TODO: how do we deal with metadata/contentful etc?
// TODO: this is starting to get brittle with having to
// call super for all lifecycle methods...see if we can
// refactor
export default class Screen extends Component {

  componentDidMount() {
    this.setupRouteEvents();
  }

  componentWillUnmount() {
    this.destroyRouteEvents();
  }

  // TODO: make this better
  _routeDidUpdate(type) {
    // Need to defer here as react-helmet is async and
    // segment.io checks for a canonical link first.
    defer(trackPage, this.constructor.name);
    if (type !== 'INIT') {
      this.scrollToTop();
    }
  }

  scrollToTop() {
    if (window.scrollY === 0) {
      return Promise.resolve();
    } else {
      return new Promise(function(resolve) {
        function onScroll() {
          if (window.scrollY === 0) {
            window.removeEventListener('scroll', onScroll);
            resolve();
          }
        }

        // Some browsers will throw an error on scrollTo
        // when an options object is passed.
        try {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          window.addEventListener('scroll', onScroll);
        } catch (err) {
          window.scrollTo(0, 0);
          resolve();
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          {this.renderTitle()}
          {this.renderDescription()}
          {this.renderCanonical()}
        </Helmet>
        {this.renderBody()}
      </React.Fragment>
    );
  }

  renderCanonical() {
    return <link rel="canonical" href={location.href} />;
  }

  renderTitle() {
    const parts = [];
    parts.push(APP_NAME);
    parts.push(this.title || this.constructor.name);
    return <title>{parts.join(' | ')}</title>;
  }

  renderDescription() {
    const { description } = this;
    if (description) {
      return (
        <React.Fragment>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </React.Fragment>
      );
    }
  }
}
