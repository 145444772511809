import { Container } from 'semantic';

import { Screen } from 'public/helpers';
import { IMAGES } from 'public/assets';
import {
  ScrollArrow,
  CardSection,
  FlipCard,
  Headquarters,
  AdaptiveImage,
  Marquee,
  Swing,
  Spacer,
  Video,
} from 'public/components';

import '../page.less';

export default class OrangeTheory extends Screen {
  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          sections={[
            {
              id: 'overview',
              top: true,
            },
          ]}
        />
        <div className={this.getElementClass('background')}>
          <AdaptiveImage
            src={IMAGES['case-studies']['orange-theory']['hero']}
          />
        </div>
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing>
              <h2 className="indent">
                A data and <em>insights engine</em> that powers the world
                leading science-backed, data-proven workout.
              </h2>
            </Swing>
            <Spacer size="l" />
          </div>
        </Container>
        <div className={this.getElementClass('container')}>
          <div className="light" id="overview">
            <Marquee sticky spaced>
              Project <em>Overview –</em>
            </Marquee>
            <Spacer size="xs" />
            <div className={this.getElementClass('overview-container')}>
              <Container className="small">
                <h3>
                  Rekall has built a Data Platform that enables data{' '}
                  <em>ingestion</em>, <em>aggregation</em>, <em>analytics</em>,{' '}
                  <em>insights</em> and <em>“actions”</em> from data captured
                  from many devices, providing a view into members’ health and
                  fitness.
                </h3>
                <Spacer size="s" />
                <h3>
                  As the most data-driven fitness franchise in the world, it’s
                  imperative that Orangetheory Fitness leverages its unique
                  workout data, using the best tools available, to deliver the
                  smartest workout experience possible.
                </h3>
                <Spacer size="l" />
              </Container>
            </div>
          </div>
          <div className="dark">
            <Spacer size="s" />
            <Container>
              <Spacer size="s" />
              <div className={this.getElementClass('inset')}>
                <h3>
                  Creating <em>“More Life”</em>
                </h3>
                <p>
                  Rekall partnered with the National Cardiovascular Board and
                  Orangetheory to develop proprietary health metric
                  calculations, such as VO2max and Heart Rate Recovery, using
                  granular data captured from Orangetheory wearable devices and
                  "smart" workout machines to better support the journey toward
                  "More Life" for members.
                </p>
              </div>
              <Spacer size="m" />
            </Container>
            <Spacer size="s" />
            <Container className="large">
              <Video name="orange-theory-section-1" />
            </Container>
            <Spacer size="s" />
            <Container>
              <Spacer size="s" />
              <p>
                In addition to building a robust data platform, Rekall also
                worked with Orangetheory to dream up next generation
                Digital-out-of-Home data visualizations to strengthen brand
                awareness and drive new member signups at Flagship Studios and
                large events like SXSW.
              </p>
              <Spacer size="m" />
            </Container>
            <Container className="large">
              <Video name="orange-theory-section-2" />
              <Spacer size="l" />
            </Container>
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Key <em>Features –</em>
            </Marquee>
            <ul className={this.getElementClass('feature-list')}>
              <li>
                <h3>Scalable, Secure Infrastructure</h3>
              </li>
              <li>
                <h3>AI / ML-based Insights</h3>
              </li>
              <li>
                <h3>Apple + OTF Enrichment</h3>
              </li>
              <li>
                <h3>Data Proven Impact</h3>
              </li>
            </ul>
            <Spacer size="l" />
          </div>
          <div className="dark">
            <CardSection title="Future Tech in Action" background>
              <FlipCard
                number="001"
                front="Proven expertise from startup to enterprise"
                back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
              />
              <FlipCard
                number="002"
                front="Proven expertise from startup to enterprise"
                back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
              />
              <FlipCard
                number="003"
                front="Proven expertise from startup to enterprise"
                back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
              />
              <FlipCard
                number="004"
                front="Proven expertise from startup to enterprise"
                back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
              />
            </CardSection>
          </div>
          {/*
          <div className="light">
            <Marquee sticky spaced>
              Project <em>Outcomes –</em>
            </Marquee>
            <div className={this.getElementClass('outcomes-container')}>
              <Container>
                <h3>VO2max Measurement Methodology</h3>
                <br />
                <h3>Backend system able to scale OTF data globally</h3>
                <br />
                <h3>
                  “Feeding the Apps” &mdash; new insight and milestone alerts
                  delivered to users providing an even more granular
                  understanding of their “more life” journey with OTF
                </h3>
              </Container>
              <Spacer size="s" />
            </div>
            <Spacer size="l" />
          </div>
          */}
          <Headquarters background />
        </div>
      </div>
    );
  }
}
