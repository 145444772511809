import { createPortal } from 'react-dom';
import { throttle } from 'lodash';

import { Component } from 'common/helpers';
import { Transition } from 'common/components';

import { ArrowButton } from '../Flair';

import './scroll-arrow.less';

export default class ScrollArrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 40,
      hidden: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  getStyles() {
    return {
      width: `${this.state.width}vw`,
    };
  }

  onScroll = throttle(() => {
    const y = window.scrollY;
    if (y < window.innerHeight) {
      const width = Math.min(100, 40 + (y / 400) * 60);
      this.setState({
        width,
      });
    } else if (this.state.width !== 100) {
      this.setState({
        width: 100,
      });
    }
    const hidden = window.innerWidth < 768 && y > 0;
    if (hidden !== this.state.hidden) {
      this.setState({
        hidden,
      });
    }
  }, 100);

  onArrowClick = () => {
    const { sections } = this.props;
    const PADDING = 40;
    for (let section of sections) {
      const el = document.getElementById(section.id);
      const rect = el.getBoundingClientRect();
      if (rect.bottom > window.innerHeight) {
        try {
          let top;
          if (section.top) {
            top = Math.round(window.scrollY + rect.top);
          } else {
            top = Math.round(
              window.scrollY + (rect.bottom - (window.innerHeight - PADDING))
            );
          }
          window.scrollTo({
            top,
            behavior: 'smooth',
          });
        } catch (err) {
          window.scrollTo(0, window.scrollY + window.innerHeight * 0.3);
        }
        return;
      }
    }
  };

  render() {
    return createPortal(this.renderContent(), document.body);
  }

  renderContent() {
    const { title, subtitle } = this.props;
    const { hidden } = this.state;
    return (
      <Transition>
        <div {...this.getProps()} style={this.getStyles()}>
          <div className={this.getElementClass('top')}>
            {title && (
              <div className={this.getElementClass('title')}>{title}</div>
            )}
            {subtitle && (
              <div className={this.getElementClass('subtitle')}>{subtitle}</div>
            )}
          </div>
          <div
            className={this.getElementClass(
              'container',
              hidden ? 'hidden' : null
            )}>
            <ArrowButton onClick={this.onArrowClick} down />
          </div>
        </div>
      </Transition>
    );
  }
}
