import PropTypes from 'prop-types';

import { Component } from 'common/helpers';

import './spacer.less';

export default class Spacer extends Component {
  getModifiers() {
    const { size, pull } = this.props;
    return [typeof size === 'string' ? size : null, pull ? 'pull' : null];
  }

  getExtraClasses() {
    return [this.props.desktop ? 'desktop-only' : null];
  }

  getStyles() {
    const { size, pull } = this.props;
    if (typeof size === 'number') {
      if (pull) {
        return {
          marginBottom: -size,
        };
      } else {
        return {
          height: size,
        };
      }
    }
  }

  render() {
    return <div {...this.getProps()} style={this.getStyles()} />;
  }
}

Spacer.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pull: PropTypes.bool,
  desktop: PropTypes.bool,
};

Spacer.defaultProps = {
  pull: false,
  desktop: false,
};
