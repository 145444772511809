import { throttle } from 'lodash';
import { Container } from 'semantic';

import { ScrollWaypoint } from 'common/components';
import { Screen } from 'public/helpers';
import {
  Swing,
  ScrollArrow,
  ScrollContainer,
  Spacer,
  Headquarters,
  Marquee,
  Stack,
} from 'public/components';

import { IMAGES } from 'public/assets';
import appleLogo from 'public/assets/apple-logo.svg';

import './services.less';

export default class Services extends Screen {
  title = 'What We Do';
  description =
    'From c-suite strategy to on-the-ground implementation, Rekall operates across your organization to define and build data-centric solutions leveraging the latest technology to solve real business problems. ';

  constructor(props) {
    super(props);
    this.state = {
      logoOffset: 0,
    };
  }

  onBrandsEntered = () => {
    if (window.innerWidth > 768) {
      this.scrollOrigin = window.scrollY;
      window.addEventListener('scroll', this.onBrandsScroll);
    }
  };

  onBrandsLeft = () => {
    window.removeEventListener('scroll', this.onBrandsScroll);
  };

  onBrandsScroll = throttle(() => {
    const logoOffset = Math.max(0, window.scrollY - this.scrollOrigin) * -0.4;
    this.setState({
      logoOffset,
    });
  }, 200);

  getLogoStyles() {
    if (window.innerWidth > 768) {
      const { logoOffset } = this.state;
      const transform = `translateX(${logoOffset}px)`;
      return {
        transform,
        WebkitTransform: transform,
      };
    }
  }

  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          sections={[
            {
              id: 'line-1',
            },
            {
              id: 'line-2',
            },
            {
              id: 'tech',
              top: true,
            },
          ]}
        />
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing>
              <h2 className="indent">
                We operate <em>across your organization,</em>
              </h2>
            </Swing>
            <Spacer size="m" />
            <Swing>
              <h3 className="indent" id="line-1">
                From c-suite strategy and business goals to{' '}
                <em>on-the-ground</em> implementation.
              </h3>
            </Swing>
            <Spacer size="s" />
            <Swing>
              <h3 className="indent" id="line-2">
                We believe in getting the work done quickly and not wasting time
                on prolonged development cycles.
              </h3>
            </Swing>
            <Spacer size="xl" />
          </div>
        </Container>
        <div className={this.getElementClass('container')}>
          <div className="dark" id="tech">
            <Spacer size="m" />
            <div className="desktop-only">
              <div className={this.getElementClass('desktop-cards')}>
                <ScrollContainer
                  space
                  cursor
                  title="Our Technology"
                  subtitle="Custom solutions for your business">
                  <div>
                    <div className={this.getElementClass('card')}>
                      <div className={this.getElementClass('card-number')}>
                        01.
                      </div>
                      <div className={this.getElementClass('card-text')}>
                        We build end-to-end solutions that are data-centric
                        using big data stores like Elasticsearch.
                      </div>
                    </div>
                  </div>
                  <div className={this.getElementClass('card')}>
                    <div className={this.getElementClass('card-number')}>
                      02.
                    </div>
                    <div className={this.getElementClass('card-text')}>
                      We deploy horizontally scalable & fault tolerant
                      infrastructure using Google Cloud & Kubernetes
                    </div>
                  </div>
                  <div className={this.getElementClass('card')}>
                    <div className={this.getElementClass('card-number')}>
                      03.
                    </div>
                    <div className={this.getElementClass('card-text')}>
                      We create world-class mobile experiences using native iOS
                      capabilities including state-of-the-art frameworks
                      pioneered by Apple.
                    </div>
                  </div>
                  <div className={this.getElementClass('card')}>
                    <div className={this.getElementClass('card-number')}>
                      04.
                    </div>
                    <div className={this.getElementClass('card-text')}>
                      Our applications are built on a robust long term platform
                      with documented JSON APIs.
                    </div>
                  </div>
                  <div className={this.getElementClass('card')}>
                    <div className={this.getElementClass('card-number')}>
                      05.
                    </div>
                    <div className={this.getElementClass('card-text')}>
                      Security and quality is ingrained in our process. Our
                      HIPAA and FDA compliant work has been independently
                      audited.
                    </div>
                  </div>
                  <div className={this.getElementClass('card')}>
                    <div className={this.getElementClass('card-number')}>
                      06.
                    </div>
                    <div className={this.getElementClass('card-text')}>
                      We build decision maker dashboards using React and a rich
                      component library (Semantic UI, interactive CSV imports,
                      interactive visualizations, etc.)
                    </div>
                  </div>
                </ScrollContainer>
              </div>
            </div>
            <div className="mobile-only">
              <div className={this.getElementClass('mobile-cards')}>
                <Container>
                  <h3 className={this.getElementClass('tech-title')}>
                    Our technology
                  </h3>
                  <h4 className={this.getElementClass('tech-subtitle')}>
                    Custom solutions for your business
                  </h4>
                  <Spacer size="m" />
                  <Stack inverted>
                    <div className={this.getElementClass('card')}>
                      <div className={this.getElementClass('card-number')}>
                        01.
                      </div>
                      <div className={this.getElementClass('card-text')}>
                        We build end-to-end solutions that are data-centric
                        using big data stores like Elasticsearch.
                      </div>
                    </div>
                    <div className={this.getElementClass('card')}>
                      <div className={this.getElementClass('card-number')}>
                        02.
                      </div>
                      <div className={this.getElementClass('card-text')}>
                        We deploy horizontally scalable & fault tolerant
                        infrastructure using Google Cloud & Kubernetes.
                      </div>
                    </div>
                    <div className={this.getElementClass('card')}>
                      <div className={this.getElementClass('card-number')}>
                        03.
                      </div>
                      <div className={this.getElementClass('card-text')}>
                        We create world-class mobile experiences using native
                        iOS capabilities including state-of-the-art frameworks
                        pioneered by Apple.
                      </div>
                    </div>
                    <div className={this.getElementClass('card')}>
                      <div className={this.getElementClass('card-number')}>
                        04.
                      </div>
                      <div className={this.getElementClass('card-text')}>
                        Our applications are built on a robust long term
                        platform with documented JSON APIs.
                      </div>
                    </div>
                    <div className={this.getElementClass('card')}>
                      <div className={this.getElementClass('card-number')}>
                        05.
                      </div>
                      <div className={this.getElementClass('card-text')}>
                        Security and quality is ingrained in our process. Our
                        HIPAA and FDA compliant work has been independently
                        audited.
                      </div>
                    </div>
                    <div className={this.getElementClass('card')}>
                      <div className={this.getElementClass('card-number')}>
                        06.
                      </div>
                      <div className={this.getElementClass('card-text')}>
                        We build decision maker dashboards using React and a
                        rich component library (Semantic UI, interactive CSV
                        imports, interactive visualizations, etc.)
                      </div>
                    </div>
                  </Stack>
                </Container>
              </div>
            </div>
          </div>
          <div className={this.getElementClass('partners')}>
            <Marquee sticky spaced>
              <em>Partners –</em>
            </Marquee>
            <Container>
              <div className={this.getElementClass('partners-title')}>
                <img
                  className={this.getElementClass('partners-logo')}
                  src={appleLogo}
                  width={64}
                  height={75}
                />
                <h3 className={this.getElementClass('partners-name')}>
                  Apple <em>Enterprise Design Lab</em>
                </h3>
              </div>
              <Spacer size="m" />
              <div className={this.getElementClass('partners-body')}>
                <p className={this.getElementClass('partners-one')}>
                  Apple’s <b>Enterprise Design Lab</b> helps enterprises
                  transform their business by accelerating ideas, sharing
                  information, and connecting employees, inventory, supply
                  chains, and customers using the Apple devices they know and
                  love.
                </p>
                <p className={this.getElementClass('partners-two')}>
                  Rekall has been working with Apple’s EDL since 2018 to bring
                  emerging technology to production-grade user experiences for
                  brands such as Nike, Gucci, Orangetheory Fitness and Triple
                  Five Group (American Dream).
                </p>
              </div>
              <Spacer size="l" />
            </Container>
          </div>
          <div className="dark">
            <div className={this.getElementClass('brands')}>
              <ScrollWaypoint
                onEnter={this.onBrandsEntered}
                onLeave={this.onBrandsLeft}>
                <div className={this.getElementClass('brands-title')}>
                  <Container>
                    <h3 className="indent">
                      Rekall has partnered with some of
                      <br />
                      the best <em>innovation-focused</em> brands.
                    </h3>
                    <hr />
                  </Container>
                </div>
                <div
                  style={this.getLogoStyles()}
                  className={this.getElementClass('brands-logos')}>
                  <img src={IMAGES['logos']['orange-theory']} />
                  <img src={IMAGES['logos']['american-dream']} />
                  <img src={IMAGES['logos']['gucci']} />
                  <img src={IMAGES['logos']['nike']} />
                  <img src={IMAGES['logos']['litmus']} />
                </div>
              </ScrollWaypoint>
            </div>
            <Headquarters background />
          </div>
        </div>
      </div>
    );
  }
}
