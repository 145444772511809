import { Helmet } from 'react-helmet-async';
import { Container } from 'semantic';

import { Screen } from 'public/helpers';
import { Spacer } from 'public/components';

import './not-found.less';

export default class NotFound extends Screen {
  renderBody() {
    return (
      <div {...this.getProps()}>
        <Helmet>
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        <div className={this.getElementClass('container')}>
          <div className="dark">
            <Container textAlign="center">
              <Spacer size="l" />
              <h1>Sorry, that page wasn't found!</h1>
              <Spacer size="xl" />
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
