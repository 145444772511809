import { GA_TRACKING_ID } from 'utils/env';

export async function trackPage(name) {
  call('config', GA_TRACKING_ID, {
    page_title: name,
    page_path: location.pathname,
  });
}

export async function track(event, action, category, label) {
  call('event', action, {
    event_category: category,
    event_label: label,
  });
}

function call(name, ...args) {
  if (typeof gtag !== 'undefined') {
    gtag(name, ...args);
  }
}
