import { Component } from 'common/helpers';

import './equalizer.less';

export default class Equalizer extends Component {
  render() {
    return (
      <div {...this.getProps()}>
        <div className={this.getElementClass('bar', '1')} />
        <div className={this.getElementClass('bar', '2')} />
        <div className={this.getElementClass('bar', '3')} />
        <div className={this.getElementClass('bar', '4')} />
        <div className={this.getElementClass('bar', '5')} />
      </div>
    );
  }
}
