import * as THREE from 'three';
import { debounce } from 'lodash';
//import CameraControls from 'camera-controls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';

import { mapLinear } from 'utils/math';

const RING_FRICTION = 0.995;
const RING_MAX_VELOCITY = 0.05;

const CAMERA_FRICTION = 0.1;

const vertexShader = `
  varying vec2 vUv;
  void main() {

  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    vUv = uv;
    gl_Position = projectionMatrix
      * modelViewMatrix
      * vec4( position, 1.0 );
  }
`;

const fragmentShader = `
  uniform float amount;
  uniform sampler2D tDiffuse;
  varying vec2 vUv;

  float random(vec2 p) {
    vec2 K1 = vec2(
      23.14069263277926, // e^pi
      2.665144142690225 // 2^sqrt(2)
    );
    return fract(cos( dot(p,K1) ) * 12345.6789);
  }

  void main() {
    vec4 color = texture2D(tDiffuse, vUv);
    vec2 uvRandom = vUv;
    uvRandom.y *= random(vec2(uvRandom.y, amount));
    color.rgb += random(uvRandom) * 0.15;
    gl_FragColor = vec4( vec3(color), 0.5);
  }
`;

export default class SceneController {
  constructor(el) {
    this.el = el;
    this.ringVelocity = 0;
    this.ringTargetRotation = null;
  }

  setEnabled(enabled) {
    if (enabled) {
      this.animate();
    } else {
      this.stop();
    }
  }

  // Scene

  createScene() {
    this.scene = new THREE.Scene();
  }

  // Lighting

  createLights() {
    this.scene.add(new THREE.AmbientLight(0x111111));
    this.addSpotlight(
      new THREE.Vector3(-646, 535, 943),
      new THREE.Vector3(-31, -8, -0.3)
    );
    this.addSpotlight(
      new THREE.Vector3(484, 276, 887),
      new THREE.Vector3(43, -22, 12)
    );
  }

  addSpotlight(position, rotation) {
    const light = new THREE.SpotLight(0xffffff, 0.3);
    light.castShadow = true;
    light.shadow.mapSize.width = 2048;
    light.shadow.mapSize.height = 2048;
    light.shadow.camera.near = 1;
    light.shadow.camera.far = 1500;
    light.shadow.camera.bias = 0.000001;
    light.position.add(position);
    light.rotation.setFromVector3(rotation);
    this.scene.add(light);
  }

  // Renderer

  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.setDimensions();
    this.el.appendChild(this.renderer.domElement);
    window.addEventListener('resize', debounce(this.onResize, 100));
  }

  setDimensions() {
    const { width, height } = this.getDimensions();
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }

  // Controls

  //createControls() {
  //CameraControls.install({ THREE });
  //this.controls = new CameraControls(this.camera, this.el);
  //this.controls.minDistance = 10;
  //this.controls.maxPolarAngle = Math.PI / 2.2;
  //this.controls.dampingFactor = .15;
  //this.controls.draggingDampingFactor = .15;
  //this.controls.verticalDragToForward = true;
  //this.controls.truckSpeed = 2;
  //}

  // Clock

  createClock() {
    this.clock = new THREE.Clock();
  }

  // GLTF

  loadGLTF(url) {
    return new Promise((resolve) => {
      const loader = new GLTFLoader();
      loader.load(url, (gltf) => {
        const { scene: root } = gltf;
        const [camera, plane, ring] = gltf.scene.children;

        ring.castShadow = true;
        ring.receiveShadow = true;
        plane.receiveShadow = true;

        this.camera = camera;
        this.cameraOrigin = this.camera.position.clone();

        // Approximate camera look target
        this.lookTarget = ring.position.clone();
        this.lookTarget.x += 30;
        this.lookTarget.y += 9.5;

        this.createScene();
        this.createLights();
        this.createClock();
        this.createRenderer();
        this.createGrainEffect();

        this.scene.add(root);
        this.ring = ring;

        for (let child of root.children) {
          if (child instanceof THREE.Mesh) {
            child.material = new THREE.MeshStandardMaterial({
              dithering: true,
              color: child.material.color.getHex(),
            });
          }
        }

        this.animate();
        resolve();
      });
    });
  }

  // Grain effect

  createGrainEffect() {
    const renderPass = new RenderPass(this.scene, this.camera);
    this.renderer.autoClear = false;

    this.composer = new EffectComposer(this.renderer);

    // this.customPass = new ShaderPass({
    //   uniforms: {
    //     tDiffuse: {
    //       value: null,
    //     },
    //     amount: {
    //       value: 0,
    //     },
    //   },
    //   vertexShader,
    //   fragmentShader,
    // });
    // this.customPass.material.transparent = true;
    this.composer.addPass(renderPass);
    // this.composer.addPass(this.customPass);
  }

  updateGrainEffect() {
    // this.customPass.uniforms.amount.value += 0.01;
  }

  updateRingVelocity() {
    let v;
    if (this.ringTargetRotation !== null) {
      const r = this.ring.rotation.y;
      v = (this.ringTargetRotation - r) * 0.05;
    } else {
      v = this.ringVelocity * RING_FRICTION;
    }
    this.ringVelocity = Math.max(
      -RING_MAX_VELOCITY,
      Math.min(RING_MAX_VELOCITY, v)
    );
  }

  setRingVelocity(velocity) {
    this.ringVelocity = velocity;
  }

  getRingRotation() {
    return this.ring.rotation.y;
  }

  setRingTargetRotation(rad) {
    this.ringTargetRotation = rad;
  }

  // Camera Offset

  setCameraRotationOffset(offset) {
    const r = mapLinear(offset, -1, 1, -Math.PI / 4, Math.PI / 4);

    const cameraTarget = new THREE.Vector2(
      this.cameraOrigin.x,
      this.cameraOrigin.z
    );
    const ringPos = new THREE.Vector2(this.lookTarget.x, this.lookTarget.z);

    cameraTarget.sub(ringPos);
    cameraTarget.rotateAround(new THREE.Vector2(0, 0), r);
    cameraTarget.add(ringPos);

    this.cameraTarget = cameraTarget;
  }

  updateCameraOffset() {
    const pos = this.camera.position;
    const target = this.cameraTarget;
    pos.x += (target.x - pos.x) * CAMERA_FRICTION;
    pos.z += (target.y - pos.z) * CAMERA_FRICTION;
    this.camera.lookAt(this.lookTarget);
  }

  // Utils

  getDimensions() {
    return {
      width: this.el.clientWidth,
      height: this.el.clientHeight,
    };
  }

  // Rendering

  animate() {
    this.render();
    this.onAnimationFrame();
  }

  stop() {
    cancelAnimationFrame(this.timer);
  }

  render = () => {
    const delta = this.clock.getDelta();
    if (this.controls) {
      this.controls.update(delta);
    }
    if (this.ring) {
      // Don't decelerate ring
      //this.updateRingVelocity();
      this.ring.rotation.y += this.ringVelocity;
    }
    if (this.cameraTarget) {
      this.updateCameraOffset();
    }
    if (this.composer) {
      this.updateGrainEffect();
      this.composer.render();
    }
    this.renderer.render(this.scene, this.camera);
  };

  onAnimationFrame = () => {
    this.render();
    this.timer = requestAnimationFrame(this.onAnimationFrame);
  };

  onResize = () => {
    this.setDimensions();
  };
}
