import { throttle } from 'lodash';
import { Container, Icon } from 'semantic';
import { withRouter } from 'react-router-dom';

import { Component } from 'common/helpers';
import { Layout, MailLink } from 'common/components';

import logoDim from 'public/assets/logo-dim.svg';

import { isGrow } from 'utils/grow';

import { scrollToTop } from 'utils/helpers';

import { Spacer } from '../Spacer';
import { ArrowButton } from '../Flair';

import './footer.less';

@withRouter
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onArrowClick = () => {
    scrollToTop();
  };

  onScroll = throttle(() => {
    const el = document.documentElement;
    const active = el.scrollTop > el.scrollHeight / 3;
    if (active !== this.state.active) {
      this.setState({
        active,
      });
    }
  }, 500);

  getModifiers() {
    const { active } = this.state;
    return [active ? 'active' : null];
  }

  render() {
    return (
      <footer {...this.getProps()}>
        <Spacer size="m" />
        <Container>
          <Layout horizontal extra padded stackable>
            <Layout.Group>
              <div className={this.getElementClass('left')}>
                <img src={logoDim} className={this.getElementClass('logo')} />
                <div className={this.getElementClass('left-desktop')}>
                  <Spacer size="m" />
                  <ArrowButton onClick={this.onArrowClick} />
                </div>
                <div
                  onClick={this.onArrowClick}
                  className={this.getElementClass('left-mobile')}>
                  Go to top
                  <Icon
                    size="small"
                    name="arrow-inset"
                    className={this.getElementClass('left-mobile-icon')}
                  />
                </div>
              </div>
            </Layout.Group>
            <Layout.Group grow>
              <div className={this.getElementClass('group')}>
                <Layout horizontal padded stackable>
                  <Layout.Group>
                    <h6>Want to collaborate?</h6>
                    <MailLink
                      className={this.getElementClass('mail')}
                      mail="info@rekall.ai"
                    />
                  </Layout.Group>
                  <Layout.Group>
                    <h6>Want to join us?</h6>
                    <MailLink
                      className={this.getElementClass('mail')}
                      mail="careers@rekall.ai"
                    />
                  </Layout.Group>
                </Layout>
              </div>
              <div className={this.getElementClass('group')}>
                <Layout horizontal spread>
                  <Layout.Group className={this.getElementClass('location')}>
                    New York
                  </Layout.Group>
                  <Layout.Group className={this.getElementClass('location')}>
                    Amsterdam
                  </Layout.Group>
                  <Layout.Group className={this.getElementClass('location')}>
                    Tokyo
                  </Layout.Group>
                </Layout>
              </div>
            </Layout.Group>
          </Layout>
        </Container>
      </footer>
    );
  }
}
