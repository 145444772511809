import { default as heroDesktop1x } from './hero-desktop@1x.jpg';
import { default as heroDesktop2x } from './hero-desktop@2x.jpg';
import { default as heroMobile1x } from './hero-mobile@1x.jpg';
import { default as heroMobile2x } from './hero-mobile@2x.jpg';

import { default as section1Desktop1x } from './section1-desktop@1x.jpg';
import { default as section1Desktop2x } from './section1-desktop@2x.jpg';
import { default as section1Mobile1x } from './section1-mobile@1x.jpg';
import { default as section1Mobile2x } from './section1-mobile@2x.jpg';

import { default as section2Desktop1x } from './section2-desktop@1x.jpg';
import { default as section2Desktop2x } from './section2-desktop@2x.jpg';
import { default as section2Mobile1x } from './section2-mobile@1x.jpg';
import { default as section2Mobile2x } from './section2-mobile@2x.jpg';

import { default as card2Desktop1x } from './card-desktop@1x.jpg';
import { default as card2Desktop2x } from './card-desktop@2x.jpg';
import { default as card2Mobile1x } from './card-mobile@1x.jpg';
import { default as card2Mobile2x } from './card-mobile@2x.jpg';

export const hero = {
  desktop: {
    '1x': heroDesktop1x,
    '2x': heroDesktop2x,
  },
  mobile: {
    '1x': heroMobile1x,
    '2x': heroMobile2x,
  },
};

export const section1 = {
  desktop: {
    '1x': section1Desktop1x,
    '2x': section1Desktop2x,
  },
  mobile: {
    '1x': section1Mobile1x,
    '2x': section1Mobile2x,
  },
};

export const section2 = {
  desktop: {
    '1x': section2Desktop1x,
    '2x': section2Desktop2x,
  },
  mobile: {
    '1x': section2Mobile1x,
    '2x': section2Mobile2x,
  },
};

export const card = {
  desktop: {
    '1x': card2Desktop1x,
    '2x': card2Desktop2x,
  },
  mobile: {
    '1x': card2Mobile1x,
    '2x': card2Mobile2x,
  },
};
