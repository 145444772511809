import { Component } from 'common/helpers';

import { IMAGES } from 'public/assets';

import { Swing, Stack, ScrollFade, Marquee } from '../Flair';
import { Spacer } from '../Spacer';
import { AdaptiveImage } from '../AdaptiveImage';

import './card-section.less';

export default class CardSection extends Component {
  render() {
    const { background, title } = this.props;
    return (
      <div {...this.getProps()}>
        {background && (
          <AdaptiveImage
            src={IMAGES['card-background']}
            className={this.getElementClass('background')}
          />
        )}
        <Spacer size="l" />
        <div className={this.getElementClass('container')}>
          <ScrollFade>
            <Marquee className={this.getElementClass('marquee')} stroke>
              <em>{title} –</em>
            </Marquee>
          </ScrollFade>
          <Swing>
            <Stack flippable>{this.props.children}</Stack>
          </Swing>
        </div>
        <Spacer size="l" />
      </div>
    );
  }
}
