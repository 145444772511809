
class FeatureDebugger {

  constructor() {
    this.features = {
      '3D': true,
      'cards': true,
      'swing': true,
      'cursor': true,
      'marquee': true,
      'animations': true,
      'scroll-fade': true,
    };
    this.listeners = [];
  }

  isEnabled(name) {
    return this.features[name];
  }

  set(name, val) {
    this.features[name] = val;
    this.fire(name);
  }

  fire(n) {
    for (let { name, fn } of this.listeners) {
      if (name === n) {
        fn(this.features[name]);
      }
    }
  }

  subscribe(name, fn) {
    const listener = this.listeners.find((listener) => {
      return listener.fn === fn;
    });
    if (!listener) {
      this.listeners.push({
        name,
        fn,
      });
    }
  }

  unsubscribe(fn) {
    this.listeners = this.listeners.filter((listener) => {
      return listener.fn !== fn;
    });
  }

}

export default new FeatureDebugger();
