import React from 'react';

import { Component } from 'common/helpers';

import { mapLinear } from 'utils/math';
import { hasTouch } from 'utils/touch';
import { FeatureDebugger } from 'utils/debug';

import { CustomCursor } from '../CustomCursor';

import './stack.less';

const SWIPE_THRESHOLD = 100;
const SWIPE_OUT = 400;
const SWIPE_ROTATION = 30;

export default class Stack extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dx: 0,
      dy: 0,
      top: 0,
      swiping: false,
      flipped: props.flippable ? false : true,
      cursorText: props.flippable ? 'Flip' : 'Swipe',
      cursorDown: false,
      cursorActive: false,
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    FeatureDebugger.subscribe('cards', this.onFeatureChange);
  }

  componentWillUnmount() {
    FeatureDebugger.unsubscribe('cards', this.onFeatureChange);
  }

  onFeatureChange = () => {
    this.forceUpdate();
  }

  getNextTop() {
    return this.state.top + 1;
  }

  getModifiers() {
    const { inverted } = this.props;
    const { swiping } = this.state;
    return [
      inverted ? 'inverted' : null,
      swiping ? 'swiping' : null,
    ];
  }

  getCoords(evt) {
    const { clientX: x , clientY: y } = evt;
    return { x, y };
  }

  getDelta(evt) {
    const coords = this.getCoords(evt);
    const dx = coords.x - this.origin.x;
    const dy = coords.y - this.origin.y;
    return { dx, dy };
  }

  getDeltaMax() {
    const { dx, dy } = this.state;
    if (Math.abs(dx) > Math.abs(dy)) {
      return dx;
    } else {
      return dy;
    }
  }

  isReadyToSwipe() {
    return Math.abs(this.getDeltaMax()) > SWIPE_THRESHOLD;
  }

  onCardMouseEnter = () => {
    this.setState({
      cursorActive: this.hasNext(),
    });
  }

  onCardMouseLeave = () => {
    this.setState({
      cursorActive: false,
    });
  }

  onMouseDown = (evt) => {
    this.onPointerDown(evt);
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    this.setState({
      cursorDown: true,
    });
  }

  onMouseMove = (evt) => {
    this.onPointerMove(evt);
  }

  onMouseUp = () => {
    this.onPointerUp();
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.setState({
      cursorDown: false,
    });
  }

  onTouchStart = (evt) => {
    const touch = this.getTouch(evt.touches);
    this.onPointerDown(touch);
    document.addEventListener('touchmove', this.onTouchMove);
    document.addEventListener('touchend', this.onTouchEnd);
    this.containerRef.current.addEventListener('touchmove', this.onContainerTouchMove);
    this.containerRef.current.addEventListener('touchend', this.onContainerTouchEnd);
  }

  onTouchMove = (evt) => {
    const touch = this.getTouch(evt.changedTouches);
    const { dx, dy } = this.getDelta(touch);
    if (Math.abs(dx) > Math.abs(dy)) {
      this.onPointerMove(touch);
    }
  }

  onTouchEnd = () => {
    this.onPointerUp();
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
    this.containerRef.current.removeEventListener('touchmove', this.onContainerTouchMove);
    this.containerRef.current.removeEventListener('touchend', this.onContainerTouchEnd);
  }

  onButtonTouchStart = () => {
    this.setState({
      cursorDown: true,
    });
    document.addEventListener('touchend', this.onButtonTouchEnd);
  }

  onButtonTouchEnd = () => {
    this.setState({
      cursorDown: false,
    });
    document.removeEventListener('touchend', this.onButtonTouchEnd);
  }

  onButtonClick = () => {
    this.flipCard();
  }

  onCardClick = () => {
    this.flipCard();
  }

  onContainerTouchMove = (evt) => {
    evt.stopPropagation();
  }

  onContainerTouchEnd = (evt) => {
    evt.stopPropagation();
  }

  flipCard() {
    this.setState({
      flipped: true,
      cursorText: 'Swipe',
    });
  }

  getTouch(touches) {
    return touches[0];
  }

  onPointerDown(evt) {
    this.origin = this.getCoords(evt);
  }

  onPointerMove(evt) {
    if (this.origin && this.state.flipped) {
      const { dx, dy } = this.getDelta(evt);
      this.setState({
        swiping: true,
        dx,
        dy,
      });
    }
  }

  onPointerUp() {
    if (this.props.flippable && this.canFlipCard()) {
      this.onCardClick();
      return;
    }
    let { top, flipped } = this.state;
    let next;
    if (this.isReadyToSwipe()) {
      next = this.getNextTop();
      if (next !== top && this.props.flippable) {
        flipped = false;
      }
    } else {
      next = top;
    }
    this.setState({
      dx: 0,
      dy: 0,
      top: next,
      swiping: false,
      flipped,
      cursorText: flipped ? 'Swipe' : 'Flip',
      cursorActive: this.hasNext(top),
    });
    this.origin = null;
  }

  canFlipCard() {
    const { dx, dy } = this.state;
    return dx === 0 && dy === 0;
  }

  hasNext(top) {
    if (top === undefined) {
      top = this.state.top;
    }
    return top < this.props.children.length;
  }

  getItemStyles(type, i) {
    switch (type) {
      case 'gone':   return this.getGoneStyles();
      case 'top':    return this.getTopStyles();
      case 'bottom': return this.getBottomStyles(i);

    }
  }

  getGoneStyles() {
    const transform = `translateY(50%) rotate(${this.lastRotation}deg)`;
    const transition = '';
    return {
      zIndex: 1,
      transform,
      transition,
      WebkitTransform: transform,
      WebkitTransition: transition,
    };
  }

  getTopStyles() {
    const { swiping } = this.state;
    const max = this.getDeltaMax();
    const s2 = SWIPE_OUT;
    const rotation = Math.min(mapLinear(max, 0, s2, 0, SWIPE_ROTATION), s2);
    const transform = `rotate(${rotation}deg)`;
    const transition = swiping ? 'none' : '';
    if (rotation !== 0) {
      this.lastRotation = rotation;
    }
    return {
      transform,
      transition,
      WebkitTransform: transform,
      WebkitTransition: transition,
    };
  }

  getBottomStyles(i) {
    const { top } = this.state;
    const layer = i - top;
    const zIndex = -layer;
    const scale = 1 - (layer * 0.04);
    const opacity = 1 - (layer * 0.2);
    const transform = `scale(${scale})`;
    return {
      zIndex,
      opacity,
      transform,
      WebkitTransform: transform,
    };
  }

  getButtonStyles() {
    const { dx, swiping } = this.state;
    const w = window.innerWidth * .3;
    const x = Math.min(w, Math.max(-w, dx));
    const transform = `translateX(${x}px)`;
    const transition = swiping ? 'none' : '';
    return {
      transition,
      transform,
      WebkitTransform: transform,
    };
  }

  render() {
    const { children } = this.props;
    const { cursorActive, cursorDown, cursorText, flipped } = this.state;
    if (!FeatureDebugger.isEnabled('cards')) {
      return null;
    }
    return (
      <div
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchStart}
        {...this.getProps()}>
        <CustomCursor
          text={cursorText}
          down={cursorDown}
          active={cursorActive}
        />
        <div
          ref={this.containerRef}
          className={this.getElementClass('container')}>
          {children.map((item, i) => this.renderItem(item, i))}
        </div>
        {hasTouch && (
          <div
            className={this.getElementClass('button-container', this.hasNext() ? null : 'hidden')}>
            <div
              style={this.getButtonStyles()}
              onTouchStart={this.onButtonTouchStart}
              onClick={this.onButtonClick}
              className={this.getElementClass('button', cursorDown ? 'down' : null)}>
              {cursorText}
            </div>
            {flipped && (
              <div className={this.getElementClass('swipe-bg')} />
            )}
          </div>
        )}
      </div>
    );
  }

  getItemType(i) {
    const { top } = this.state;
    if (i < top) {
      return 'gone';
    } else if (i === top) {
      return 'top';
    } else {
      return 'bottom';
    }
  }

  renderItem(item, i) {
    const { top, flipped } = this.state;
    const ready = this.isReadyToSwipe();
    const isNext = i === top + 1;
    const type = this.getItemType(i);
    let opts = {
      onMouseEnter: this.onCardMouseEnter,
      onMouseLeave: this.onCardMouseLeave,
    };
    if (this.props.flippable) {
      opts = {
        flipped: i === top && flipped,
        ...opts,
      };
    }
    item = React.cloneElement(item, opts);
    return (
      <div
        key={i}
        style={this.getItemStyles(type, i)}
        className={this.getElementClass('item', type, ready && isNext ? 'ready' : null)}>
        {item}
      </div>
    );
  }

}
