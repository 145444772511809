import { Container } from 'semantic';

import { IMAGES } from 'public/assets';
import {
  AdaptiveImage,
  Headquarters,
  Marquee,
  ScrollArrow,
  Spacer,
  Swing,
} from 'public/components';
import { Screen } from 'public/helpers';

import '../page.less';

export default class EFlux extends Screen {
  title = 'E-Flux';
  description =
    'An open platform built to enable hyper growth of EV charging applications.';

  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          title="E-Flux"
          subtitle="API first development"
          sections={[
            {
              id: 'overview',
              top: true,
            },
          ]}
        />
        <div className={this.getElementClass('background')}>
          <AdaptiveImage src={IMAGES['case-studies']['e-flux']['hero']} />
        </div>
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing>
              <h2 className="indent">
                An open platform built to enable hyper growth of EV charging
                applications.
              </h2>
            </Swing>
            <Spacer size="l" />
          </div>
        </Container>
        <div className={this.getElementClass('container')}>
          <div className="light" id="overview">
            <Marquee sticky spaced>
              Project <em>Overview –</em>
            </Marquee>
            <Spacer size="xs" />
            <div className={this.getElementClass('overview-container')}>
              <Container className="small">
                <h3>
                  The infrastructure supporting EVs today is fragmented, with
                  companies managing fleets forced to juggle multiple vendors
                  with disparate systems to access their data. This
                  fragmentation leads to higher costs and poor service for both
                  customers and companies alike.
                </h3>
                <Spacer size="s" />
                <h3>
                  Rekall developed an open eMobility cloud solution to power all
                  EV charging and unify a fragmented vendor landscape. By
                  working with Rekall, E-Flux now has a scalable end-to-end
                  software solution complemented by world-class service and
                  support. Thanks to the software platform and E-Flux's
                  operational excellence, the company is growing 600% YoY and is
                  poised to become Europe's leading EV SaaS solution.
                </h3>
                <Spacer size="l" />
              </Container>
            </div>
          </div>
          <div className="dark">
            <Spacer size="m" />
            <Container className="small">
              <p>
                E-Flux chose Rekall for our modern approach to architecture
                design, as well as our expertise in creating data-centric end
                user experiences. When dealing with a landscape as fragmented as
                eMobility is today, a team that can confidently navigate many
                vendors, often with conflicting technology stacks, to create a
                unified solution was essential.
              </p>
              <Spacer size="m" />
            </Container>
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['e-flux']['section1']}
              />
              <Spacer size="m" />
            </Container>
            <Container className="small">
              <p>
                By working with Rekall, E-Flux now has a scalable technology
                offering lightyears ahead of the competition.
              </p>
              <Spacer size="m" />
            </Container>
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['e-flux']['section2']}
              />
              <Spacer size="m" />
            </Container>
            <Spacer size="m" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Key <em>Features –</em>
            </Marquee>
            <ul className={this.getElementClass('feature-list')}>
              <li>
                <h3>Scalable, Fault Tolerant Infrastructure</h3>
              </li>
              <li>
                <h3>Hardware agnostic end-to-end solution</h3>
              </li>
              <li>
                <h3>Secure and Compliant</h3>
              </li>
              <li>
                <h3>Data-Centric Applications and Services</h3>
              </li>
              <li>
                <h3>Open API</h3>
              </li>
            </ul>
            <Spacer size="l" />
          </div>
          <div className="dark">
            <Spacer size="l" />
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['e-flux']['section3']}
              />
            </Container>
            <Spacer size="l" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Project <em>Outcomes –</em>
            </Marquee>
            <div className={this.getElementClass('outcomes-container')}>
              <Container>
                <div className={this.getElementClass('left-offset')}>
                  <h3>
                    By using Rekall as a technology partner, E-Flux has a
                    flexible full spectrum SaaS and mobile EV charging solution
                    built using modern big data technologies to provide a
                    competitive turnkey solution to a wide array of industry
                    verticals.
                  </h3>
                </div>
                <Spacer size="m" />
                <blockquote className={this.getElementClass('outcomes-quote')}>
                  <div>
                    <span
                      className={this.getElementClass('outcomes-quote-top')}>
                      Working with Rekall has allowed us to outpace our
                      competitors and grow our market share exponentially.
                    </span>{' '}
                    <span>
                      The speed at which the team has deployed, innovated, and
                      adapted our technology offering has been a critical
                      element to our incredible success.
                    </span>
                  </div>
                </blockquote>
                <Spacer size="s" />
                <div className={this.getElementClass('outcomes-quote-title')}>
                  Vincent van Vaalen, CEO
                </div>
              </Container>
            </div>
            <Spacer size="l" />
          </div>
          <Headquarters background />
        </div>
      </div>
    );
  }
}
