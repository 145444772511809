import { Component } from 'common/helpers';

import { FeatureDebugger } from 'utils/debug';

import './debug-panel.less';

const FRAME_WINDOW = 60;

export default class DebugPanel extends Component {
  constructor(props) {
    super(props);
    this.frame = 0;
    this.markTime = 0;
    this.markFrame = 0;
    this.state = {
      fps: null,
    };
  }

  componentDidMount() {
    this.parseQueryParams();
    if (this.isEnabled()) {
      this.tick();
      FeatureDebugger.subscribe('animations', this.onAnimationsChange);
    }
  }

  componentWillUnmount() {
    this.disable();
  }

  disable() {
    cancelAnimationFrame(this.timer);
    FeatureDebugger.unsubscribe('animations', this.onAnimationsChange);
  }

  parseQueryParams() {
    const params = new URLSearchParams(location.search);
    if (params.has('debug')) {
      localStorage.setItem('debug', '1');
    }
  }

  isEnabled() {
    return !!localStorage.getItem('debug');
  }

  tick = () => {
    this.frame++;
    if (this.frame - this.markFrame === FRAME_WINDOW) {
      this.update();
    }
    this.timer = requestAnimationFrame(this.tick);
  };

  onFeatureChange = (evt) => {
    const { id, checked } = evt.target;
    FeatureDebugger.set(id, checked);
    this.forceUpdate();
  };

  onAnimationsChange = (enabled) => {
    if (enabled) {
      document.documentElement.classList.remove('no-animations');
    } else {
      document.documentElement.classList.add('no-animations');
    }
  };

  onCloseClick = () => {
    localStorage.removeItem('debug');
    this.disable();
    this.forceUpdate();
  };

  update() {
    const markTime = Date.now();
    if (this.markTime) {
      const time = markTime - this.markTime;
      const fps = Math.round((FRAME_WINDOW / time) * 1000);
      this.setState({
        fps,
      });
    }
    this.markTime = markTime;
    this.markFrame = this.frame;
  }

  render() {
    if (!this.isEnabled()) {
      return null;
    }
    const { fps } = this.state;
    return (
      <div {...this.getProps()}>
        <div
          onClick={this.onCloseClick}
          className={this.getElementClass('close')}>
          &times;
        </div>
        {this.renderFeature('3D', '3D')}
        {this.renderFeature('Swing', 'swing')}
        {this.renderFeature('Cards', 'cards')}
        {this.renderFeature('Cursor', 'cursor')}
        {this.renderFeature('Marquee', 'marquee')}
        {this.renderFeature('Animations', 'animations')}
        {this.renderFeature('Scroll Fade', 'scroll-fade')}
        {fps && <div className={this.getElementClass('fps')}>FPS: {fps}</div>}
      </div>
    );
  }

  renderFeature(label, name) {
    return (
      <fieldset>
        <input
          id={name}
          type="checkbox"
          checked={FeatureDebugger.isEnabled(name)}
          onChange={this.onFeatureChange}
        />{' '}
        <label htmlFor={name}>{label}</label>
      </fieldset>
    );
  }
}
