import React from 'react';
import { throttle } from 'lodash';

import { Component } from 'common/helpers';

import { FeatureDebugger } from 'utils/debug';

import './marquee.less';

const COPIES = 30;

export default class Marquee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
    };
    this.ref = React.createRef();
  }

  getModifiers() {
    const { spaced, sticky, stroke } = this.props;
    const { hidden } = this.state;
    return [
      spaced ? 'spaced' : 'pull',
      sticky ? 'sticky' : null,
      hidden ? 'hidden' : null,
      stroke ? 'stroke' : null,
    ];
  }

  componentDidMount() {
    FeatureDebugger.subscribe('marquee', this.onFeatureChange);
  }

  componentWillUnmount() {
    FeatureDebugger.unsubscribe('marquee', this.onFeatureChange);
  }

  onFeatureChange = () => {
    this.forceUpdate();
  };

  // Don't hide for now
  //componentDidMount() {
  //const { sticky } = this.props;
  //if (sticky) {
  //window.addEventListener('scroll', this.onScroll);
  //}
  //}

  //componentWillUnmount() {
  //window.removeEventListener('scroll', this.onScroll);
  //}

  onScroll = throttle(() => {
    const el = this.ref.current;
    const rect = el.getBoundingClientRect();
    const hidden = rect.top === 0;
    if (hidden !== this.state.hidden) {
      this.setState({
        hidden,
      });
    }
  }, 300);

  render() {
    const { id } = this.props;
    if (!FeatureDebugger.isEnabled('marquee')) {
      return null;
    }
    return (
      <marquee id={id} ref={this.ref} {...this.getProps()}>
        {this.renderText()}
      </marquee>
    );
  }

  renderText() {
    return (
      <React.Fragment>
        {Array.from(new Array(COPIES)).map(() => {
          return this.props.children;
        })}
      </React.Fragment>
    );
  }
}
