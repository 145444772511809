import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Icon } from 'semantic';

import { Header, Footer, DebugPanel } from 'public/components';
import {
  About,
  AmericanDream,
  CaseStudies,
  Contact,
  Grow,
  Home,
  Eflux,
  LitmusHealth,
  Nuelife,
  Housebar,
  NotFound,
  OrangeTheory,
  Privacy,
  Services,
  Terms,
  StaticRedirect,
} from 'public/screens';

import 'utils/sentry/init';

// Icons
import ICON_URL from 'public/assets/icons.svg';
Icon.useSet(ICON_URL);

import { isGrow } from 'utils/grow';

import './app.less';

class App extends React.Component {
  state = {
    animationPlayed: false,
  };

  onIntroPlayed = () => {
    this.setState({
      animationPlayed: true,
    });
  };

  render() {
    const { animationPlayed } = this.state;
    return (
      <React.Fragment>
        <DebugPanel />
        <Header onIntroPlayed={this.onIntroPlayed} />
        {animationPlayed && (
          <React.Fragment>
            {isGrow() ? (
              <main className="grow">
                <Switch>
                  <Route path="*" component={Grow} exact />
                </Switch>
              </main>
            ) : (
              <main>
                <Switch>
                  <Route path="/" component={Home} exact />
                  <Route path="/about" component={About} exact />
                  <Route path="/contact" component={Contact} exact />
                  <Route path="/services" component={Services} exact />
                  <Route path="/case-studies" component={CaseStudies} exact />
                  <Route
                    path="/case-studies/american-dream"
                    component={AmericanDream}
                    exact
                  />
                  <Route
                    path="/case-studies/orange-theory"
                    component={OrangeTheory}
                    exact
                  />
                  <Route
                    path="/case-studies/litmus-health"
                    component={LitmusHealth}
                    exact
                  />
                  <Route path="/case-studies/e-flux" component={Eflux} exact />
                  <Route
                    path="/case-studies/nuelife"
                    component={Nuelife}
                    exact
                  />
                  <Route
                    path="/case-studies/housebar"
                    component={Housebar}
                    exact
                  />
                  <Route path="/privacy" component={Privacy} exact />
                  <Route path="/terms" component={Terms} exact />
                  <Route path="/grow" component={Grow} exact />
                  <StaticRedirect path="/company" to="/about" />
                  <Route component={NotFound} />
                </Switch>
              </main>
            )}
            <Footer />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default hot(App);
