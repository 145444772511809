import orangeTheory from './orange-theory.png';
import americanDream from './american-dream.png';
import gucci from './gucci.png';
import nike from './nike.png';
import litmus from './litmus.png';

export default {
  'american-dream': americanDream,
  'orange-theory': orangeTheory,
  litmus: litmus,
  gucci: gucci,
  nike: nike,
};
