import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from 'semantic';

import { Link, Route } from 'react-router-dom';

import { Spacer } from 'public/components';

import { APP_URL } from '../../utils/env';

export default class StaticRedirect extends React.Component {
  render() {
    const { path } = this.props;
    return <Route path={path} render={this.renderRedirect} />;
  }

  renderRedirect = () => {
    const { to } = this.props;
    const url = `${APP_URL}${to}`;
    return (
      <div>
        <Helmet>
          <meta name="prerender-status-code" content="301" />
          <meta name="prerender-header" content={`Location: ${url}`} />
        </Helmet>
        <div>
          <div className="dark">
            <Container textAlign="center">
              <Spacer size="l" />
              <h1>
                That page has{' '}
                <Link to={to}>
                  <span
                    style={{ color: '#4289c3', textDecoration: 'underline' }}>
                    moved
                  </span>
                </Link>
                .
              </h1>
              <Spacer size="xl" />
            </Container>
          </div>
        </div>
      </div>
    );
  };
}
