import { Container } from 'semantic';

import { IMAGES } from 'public/assets';
import {
  AdaptiveImage,
  Headquarters,
  Marquee,
  ScrollArrow,
  Spacer,
  Swing,
} from 'public/components';
import { Screen } from 'public/helpers';

import '../page.less';

export default class LitmusHealth extends Screen {
  title = 'Litmus Health';
  description =
    'Rekall has built a next generation, clinical grade data science platform for Litmus Health that helps researchers better understand patients’ behavior and environment by using data collected from wearables, smart devices, sensors and ePRO surveys to guide management and to inform trial endpoints.';

  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          title="Litmus Health"
          subtitle="Future-proof development"
          sections={[
            {
              id: 'overview',
              top: true,
            },
          ]}
        />
        <div className={this.getElementClass('background')}>
          <AdaptiveImage
            src={IMAGES['case-studies']['litmus-health']['hero']}
          />
        </div>
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing>
              <h2 className="indent">
                A clinical grade <em>(data science platform)</em> for clinical
                trials and research
              </h2>
            </Swing>
            <Spacer size="l" />
          </div>
        </Container>
        <div className={this.getElementClass('container')}>
          <div className="light" id="overview">
            <Marquee sticky spaced>
              Project <em>Overview –</em>
            </Marquee>
            <Spacer size="xs" />
            <div className={this.getElementClass('overview-container')}>
              <Container className="small">
                <h3>
                  Rekall has built a next generation data science platform to
                  enhance researcher confidence in their findings.
                </h3>
                <Spacer size="s" />
                <h3>
                  The Litmus Health platform helps researchers better understand
                  patients’ behavior and environment by using data collected
                  from wearables, smart devices, sensors and ePRO surveys to
                  guide management and to inform trial endpoints.
                </h3>
                <Spacer size="l" />
              </Container>
            </div>
          </div>
          <div className="dark">
            <Spacer size="l" />
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['litmus-health']['section1']}
              />
              <Spacer size="m" />
            </Container>
            <Container className="small">
              <Spacer size="m" />
              <p>
                In addition to developing a HIPAA & FDA compliant clinical trial
                platform, Rekall provided strategic consulting, product
                planning, experience design and engineering services for the
                entire Litmus Health Ecosystem and served as a critical resource
                impacting every aspect of Litmus Health.
              </p>
            </Container>
            <Spacer size="l" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Key <em>Features –</em>
            </Marquee>
            <ul className={this.getElementClass('feature-list')}>
              <li>
                <h3>Scalable, Secure & Fault-Tolerant Infrastructure</h3>
              </li>
              <li>
                <h3>HIPAA and FDA 21 CFR Part 11 Compliance</h3>
              </li>
              <li>
                <h3>Independently Tested for Security & Compliance</h3>
              </li>
              <li>
                <h3>Cross-Platform Mobile & Wearable Support</h3>
              </li>
              <li>
                <h3>Machine Learning to Boost Patient Adherence</h3>
              </li>
            </ul>
            <Spacer size="l" />
          </div>
          <div className="dark">
            <Spacer size="l" />
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['litmus-health']['section2']}
              />
            </Container>
            <Spacer size="l" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Project <em>Outcomes –</em>
            </Marquee>
            <div className={this.getElementClass('outcomes-container')}>
              <Container>
                <div className={this.getElementClass('left-offset')}>
                  <h3>
                    By using Rekall as a technology partner, Litmus Health has a
                    world class data platform that stands up to independent
                    audits and is now used and trusted by major pharmaceutical
                    companies.
                  </h3>
                </div>
                <Spacer size="m" />
                <blockquote className={this.getElementClass('outcomes-quote')}>
                  <div>
                    <span
                      className={this.getElementClass('outcomes-quote-top')}>
                      What I get with Rekall is even better than a co-founder.
                    </span>{' '}
                    <span>
                      I get a co-founder, a CTO, a VP Eng, a mobile app dev, and
                      more!
                    </span>
                  </div>
                </blockquote>
                <Spacer size="s" />
                <div className={this.getElementClass('outcomes-quote-title')}>
                  Josh-Jones Dilsworth
                </div>
                <div
                  className={this.getElementClass('outcomes-quote-subtitle')}>
                  Litmus Health Co-Founder & Board Director
                </div>
              </Container>
            </div>
            <Spacer size="l" />
          </div>
          <Headquarters background />
        </div>
      </div>
    );
  }
}
