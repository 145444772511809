import { Icon } from 'semantic';

import { Component } from 'common/helpers';
import { MailLink } from 'common/components';

import { IMAGES } from 'public/assets';

import { Swing, CustomCursor } from '../Flair';
import { AdaptiveImage } from '../AdaptiveImage';

import './headquarters.less';

export default class Headquarters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cursorActive: false,
    };
  }

  onMouseEnter = () => {
    this.setState({
      cursorActive: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      cursorActive: false,
    });
  };

  render() {
    return (
      <div {...this.getProps()}>
        <AdaptiveImage
          src={IMAGES['headquarters-background']}
          className={this.getElementClass('background')}
        />
        <MailLink
          mail="info@rekall.ai"
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className={this.getElementClass('container')}>
          <Swing>
            <h2 className="indent">
              Rekall is <em>headquartered</em> in New York City,
            </h2>
          </Swing>
          <Swing>
            <h2 className="indent">with global expertise and reach.</h2>
          </Swing>
          <Swing>
            <div className={this.getElementClass('mail')}>
              Drop us a line at info@rekall.ai
            </div>
          </Swing>
          <div className={this.getElementClass('mobile-cta')}>
            <span className={this.getElementClass('mobile-cta-icon')}>
              <Icon name="arrow-diagonal" size="tiny" />
            </span>
            Email us
          </div>
        </MailLink>
        <CustomCursor text="Email Us" active={this.state.cursorActive} />
      </div>
    );
  }
}
