import React from 'react';

import { ScrollWaypoint } from 'common/components';

import { GCLOUD_VIDEOS_BUCKET } from 'utils/env';

const URL_BASE = `https://storage.googleapis.com/${GCLOUD_VIDEOS_BUCKET}`;

export default class Video extends React.Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  onEnter = () => {
    this.ref.current.play();
  }

  render() {
    const { name } = this.props;
    return (
      <ScrollWaypoint onEnter={this.onEnter}>
        <video ref={this.ref} loop muted playsInline>
          <source
            src={`${URL_BASE}/${name}.h265.mp4`}
            type="video/mp4; codecs='hevc,hvc1,h265'"
          />
          <source
            src={`${URL_BASE}/${name}.h264.mp4`}
            type="video/mp4;"
          />
        </video>
      </ScrollWaypoint>
    );
  }

}
