import { Container } from 'semantic';

import { Screen } from 'public/helpers';
import { IMAGES } from 'public/assets';
import {
  Spacer,
  ScrollArrow,
  AdaptiveImage,
  Headquarters,
  Marquee,
  Swing,
} from 'public/components';

import '../page.less';

export default class Nuelife extends Screen {
  title = 'nue.life';
  description =
    'A mobile application created to provide those suffering with depression a streamlined path to healing.';

  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          title="nue.life"
          subtitle="Technology powering new perspectives"
          sections={[
            {
              id: 'overview',
              top: true,
            },
          ]}
        />
        <div className={this.getElementClass('background')}>
          <AdaptiveImage src={IMAGES['case-studies']['nuelife']['hero']} />
        </div>
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing>
              <h2 className="indent">
                A mobile application created to provide those suffering with
                depression a streamlined path to healing.
              </h2>
            </Swing>
            <Spacer size="l" />
          </div>
        </Container>
        <div className={this.getElementClass('container')}>
          <div className="light" id="overview">
            <Marquee sticky spaced>
              Project <em>Overview –</em>
            </Marquee>
            <Spacer size="xs" />
            <div className={this.getElementClass('overview-container')}>
              <Container className="small">
                <h3>
                  Today Mental Health just doesn't have the focus it deserves.
                  It's often difficult for those that are struggling to find a
                  path to a solution. Rekall along with the healthcare
                  professionals at nue.life developed an easy to use mobile
                  application to help patients along their journey to a
                  healthier mindset.
                </h3>
                <Spacer size="m" />
                <h3>
                  The app helps to set intention and track mood as users
                  progress through treatment. Users can create curated
                  soundtracks to enhance their experience and discover new tools
                  for better self care through recommended articles. Technology
                  and best in-class care are on display to help patients heal
                  their most important relationship-the one with themselves.
                </h3>
                <Spacer size="l" />
              </Container>
            </div>
          </div>
          <div className="dark">
            <Spacer size="m" />
            <Container>
              <div className={this.getElementClass('inset')}>
                <h3>Building something nue</h3>
                <p>
                  Nue.life chose Rekall for our modern approach to architecture
                  design, as well as our expertise in creating user centric,easy
                  to use, friendly mobile applications. When dealing with
                  something as important as mental care, a team that can
                  confidently design exceptionally intuitive applications that
                  create a positive experience for users was critical.
                </p>
              </div>
            </Container>
            <Spacer size="m" />
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['nuelife']['section2']}
              />
              <Spacer size="m" />
            </Container>
            <Container className="small">
              <Spacer size="m" />
              <p>
                Nue.life now has a beautifully designed mobile application that
                is ready to help patients succeed in their path back to
                wellness.
              </p>
            </Container>
            <Spacer size="l" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Key <em>Features –</em>
            </Marquee>
            <ul className={this.getElementClass('feature-list')}>
              <li>
                <h3>Full in-app treatment registration</h3>
              </li>
              <li>
                <h3>Virtual Telemedicine</h3>
              </li>
              <li>
                <h3>Intention based musicologist-designed playlists</h3>
              </li>
              <li>
                <h3>Voice journaling</h3>
              </li>
              <li>
                <h3>Apple Watch connectivity</h3>
              </li>
              <li>
                <h3>Mood Tracker</h3>
              </li>
            </ul>
            <Spacer size="l" />
          </div>
          <div className="dark">
            <Spacer size="l" />
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['nuelife']['section1']}
              />
            </Container>
            <Spacer size="l" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Project <em>Outcomes –</em>
            </Marquee>
            <div className={this.getElementClass('outcomes-container')}>
              <Container>
                <div className={this.getElementClass('left-offset')}>
                  <h3>
                    By using Rekall, nue.life is utilizing best-in-class mobile
                    technology uniquely designed to help patients successfully
                    navigate their treatments.
                  </h3>
                </div>
                {/*
                <Spacer size="m" />
                <blockquote className={this.getElementClass('outcomes-quote')}>
                  <div>
                    <span
                      className={this.getElementClass('outcomes-quote-top')}>
                      What I get with Rekall is even better than a co-founder.
                    </span>{' '}
                    <span>
                      I get a co-founder, a CTO, a VP Eng, a mobile app dev, and
                      more!
                    </span>
                  </div>
                </blockquote>
                <Spacer size="s" />
                <div className={this.getElementClass('outcomes-quote-title')}>
                  Josh-Jones Dilsworth
                </div>
                <div
                  className={this.getElementClass('outcomes-quote-subtitle')}>
                  Litmus Health Co-Founder & Board Director
                </div>
                */}
              </Container>
            </div>
            <Spacer size="l" />
          </div>
          <Headquarters background />
        </div>
      </div>
    );
  }
}
