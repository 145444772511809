import React from 'react';
import { debounce } from 'lodash';
import { Container } from 'semantic';

import { Component } from 'common/helpers';
import { Layout } from 'common/components';

import { Spacer } from '../Spacer';
import { ArrowButton, CustomCursor } from '../Flair';

import './scroll-container.less';

export default class ScrollContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollable: false,
      cursorActive: false,
    };
    this.scrollRef = React.createRef();
    this.onWindowResize = debounce(this.onWindowResize, 300);
  }

  componentDidMount() {
    this.checkScrollable();
    window.addEventListener('resize', this.onWindowResize);
    if (this.props.onMount) {
      this.props.onMount(this.scrollRef.current);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  checkScrollable() {
    const { current: el } = this.scrollRef;
    this.setState({
      scrollable: el.scrollWidth > el.clientWidth,
    });
  }

  onLeftClick = () => {
    this.scrollCards(-1);
  };

  onRightClick = () => {
    this.scrollCards(1);
  };

  onWindowResize = () => {
    this.checkScrollable();
  };

  onMouseDown = (evt) => {
    this.origin = {
      x: evt.clientX,
      left: this.scrollRef.current.scrollLeft,
    };
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  };

  onMouseMove = (evt) => {
    const left = this.origin.left - (evt.clientX - this.origin.x);
    this.scrollRef.current.scrollTo(left, 0);
  };

  onMouseUp = () => {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  };

  onMouseEnter = () => {
    this.setState({
      cursorActive: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      cursorActive: false,
    });
  };

  scrollCards(dir) {
    const { current: el } = this.scrollRef;
    el.scrollBy({
      left: window.innerWidth * 0.8 * dir,
      behavior: 'smooth',
    });
  }

  render() {
    const { largeTitle, dark, cursor, id } = this.props;
    const { scrollable } = this.state;
    return (
      <div {...this.getProps()}>
        <Container>
          <Layout horizontal center spread>
            <Layout.Group>
              <h3
                id={id}
                className={this.getElementClass(
                  'title',
                  largeTitle ? 'large' : null,
                  dark ? 'dark' : null
                )}>
                {this.props.title}
              </h3>
              <h3 className={this.getElementClass('subtitle')}>
                {this.props.subtitle}
              </h3>
            </Layout.Group>
            <Layout.Group>
              {scrollable && (
                <Layout horizontal>
                  <Layout.Group>
                    <ArrowButton
                      onClick={this.onLeftClick}
                      width={64}
                      height={64}
                      dark={dark}
                      left
                    />
                  </Layout.Group>
                  <Layout.Group
                    className={this.getElementClass('arrow-group', 'right')}>
                    <ArrowButton
                      onClick={this.onRightClick}
                      width={64}
                      height={64}
                      dark={dark}
                      right
                    />
                  </Layout.Group>
                </Layout>
              )}
            </Layout.Group>
          </Layout>
        </Container>
        {this.props.space && <Spacer size="m" />}
        <div
          ref={this.scrollRef}
          onMouseDown={this.onMouseDown}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className={this.getElementClass(
            'items',
            cursor ? 'no-cursor' : null
          )}>
          {this.props.children.map((child, i) => {
            return (
              <div key={i} className={this.getElementClass('item')}>
                {child}
              </div>
            );
          })}
        </div>
        {cursor && (
          <CustomCursor text="Drag" active={this.state.cursorActive} />
        )}
      </div>
    );
  }
}
