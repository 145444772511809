import { Component } from 'common/helpers';

import './caption.less';

export default class Caption extends Component {
  render() {
    const { progress, children: text } = this.props;
    return (
      <div>
        {text.split(/[\s|]/).map((word, i, arr) => {
          if (!word) {
            return null;
          }
          const active = i / arr.length < progress;
          return (
            <span
              key={i}
              className={this.getElementClass(
                'word',
                active ? 'active' : null
              )}>
              {word}
            </span>
          );
        })}
      </div>
    );
  }
}
