import * as americanDream from './american-dream';
import * as orangeTheory from './orange-theory';
import * as litmusHealth from './litmus-health';
import * as eFlux from './e-flux';
import * as nuelife from './nuelife';
import * as housebar from './housebar';

export default {
  'american-dream': americanDream,
  'orange-theory': orangeTheory,
  'litmus-health': litmusHealth,
  'e-flux': eFlux,
  nuelife,
  housebar,
};
