import React from 'react';
import { throttle } from 'lodash';
import { NavLink, withRouter } from 'react-router-dom';
import { Icon } from 'semantic';

import { Component } from 'common/helpers';
import { ExternalLink, Transition } from 'common/components';

import logoRekall from 'public/assets/logo.svg';
import logoGrow from 'public/assets/logo-grow.svg';

import { isGrow } from 'utils/grow';

import { Scene } from '../Scene';

import './header.less';

@withRouter
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: window.scrollY === 0,
      panelActive: false,
      introPlayed: false,
      navEntered: false,
    };
    this.onWindowScroll = throttle(this.onWindowScroll, 300);
  }

  openPanel = () => {
    this.setState({
      panelActive: true,
    });
  };

  closePanel = () => {
    this.setState({
      panelActive: false,
    });
  };

  // Lifecycle

  routeDidUpdate() {
    this.closePanel();
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onWindowScroll);
    this.setupRouteEvents();
    setTimeout(() => {
      this.setState({
        introPlayed: true,
      });
      this.props.onIntroPlayed();
    }, 2200);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
    this.destroyRouteEvents();
  }

  componentDidUpdate(lastProps, lastState) {
    const { panelActive } = this.state;
    if (panelActive !== lastState.panelActive) {
      const docEl = document.documentElement;
      if (panelActive) {
        docEl.classList.add('fixed');
      } else {
        docEl.classList.remove('fixed');
      }
    }
  }

  // Events

  onWindowScroll = () => {
    const isTop = window.scrollY === 0;
    if (isTop !== this.state.isTop) {
      this.setState({
        isTop,
      });
    }
  };

  onNavMouseEnter = () => {
    this.setState({
      navEntered: true,
    });
  };

  onNavMouseLeave = () => {
    this.setState({
      navEntered: false,
    });
  };

  // Logo

  getLogoProps(screen) {
    if (isGrow()) {
      if (screen === 'mobile') {
        return {
          src: logoGrow,
          width: 169,
          height: 57,
        };
      } else {
        return {
          src: logoGrow,
          width: 169,
          height: 57,
        };
      }
    } else {
      if (screen === 'mobile') {
        return {
          src: logoRekall,
          width: 129,
          height: 25,
        };
      } else {
        return {
          src: logoRekall,
          width: 169,
          height: 32,
        };
      }
    }
  }

  render() {
    const { panelActive, introPlayed, isTop, navEntered } = this.state;
    const isMobile = window.innerWidth < 768;
    return (
      <header {...this.getProps()}>
        <Transition>
          <div className={this.getElementClass('swipe-in')} />
        </Transition>
        <Scene fixed={panelActive} />
        <div className={this.getElementClass('overlay')}>
          <Transition in={!isTop}>
            <div className={this.getElementClass('overlay-content')}>
              <NavLink
                to="/"
                className={this.getElementClass('overlay-home-link')}>
                <Transition>
                  <img
                    {...this.getLogoProps('desktop')}
                    className={this.getElementClass(
                      'logo',
                      !isTop && introPlayed ? 'hidden' : null
                    )}
                  />
                </Transition>
              </NavLink>
              {!isGrow() && (
                <React.Fragment>
                  <Transition
                    in={introPlayed && !panelActive && (!isTop || isMobile)}>
                    <div
                      onClick={this.openPanel}
                      className={this.getElementClass('open-button')}
                    />
                  </Transition>
                  <Transition
                    in={introPlayed && !panelActive && isTop && !isMobile}>
                    <div className={this.getElementClass('inline-nav')}>
                      <Transition>
                        <NavLink
                          to="/services"
                          className={this.getElementClass('inline-nav-link')}>
                          Services
                        </NavLink>
                      </Transition>
                      <Transition>
                        <NavLink
                          to="/case-studies"
                          className={this.getElementClass('inline-nav-link')}>
                          Case Studies
                        </NavLink>
                      </Transition>
                      <Transition>
                        <NavLink
                          to="/about"
                          className={this.getElementClass('inline-nav-link')}>
                          About
                        </NavLink>
                      </Transition>
                      <Transition>
                        <NavLink
                          to="/contact"
                          className={this.getElementClass('inline-nav-link')}>
                          Contact
                        </NavLink>
                      </Transition>
                    </div>
                  </Transition>
                </React.Fragment>
              )}
            </div>
          </Transition>
        </div>
        <div
          className={this.getElementClass(
            'nav-panel',
            panelActive ? 'active' : null
          )}>
          <NavLink to="/" className={this.getElementClass('nav-desktop-link')}>
            <Transition in={panelActive}>
              <img
                {...this.getLogoProps('desktop')}
                className={this.getElementClass('nav-desktop-logo')}
              />
            </Transition>
          </NavLink>
          <div className={this.getElementClass('nav-content')}>
            <div className={this.getElementClass('nav-top')}>
              <NavLink
                to="/"
                className={this.getElementClass('nav-mobile-link')}>
                <img
                  {...this.getLogoProps('mobile')}
                  className={this.getElementClass('logo')}
                />
              </NavLink>
              <Icon
                name="close"
                width={30}
                height={30}
                onClick={this.closePanel}
                className={this.getElementClass('nav-close-button')}
              />
            </div>
            <div className={this.getElementClass('nav-links')}>
              <NavLink
                to="/services"
                onMouseEnter={this.onNavMouseEnter}
                onMouseLeave={this.onNavMouseLeave}
                className={this.getElementClass(
                  'nav-link',
                  navEntered ? 'dim' : null
                )}>
                Services
              </NavLink>
              <NavLink
                to="/case-studies"
                onMouseEnter={this.onNavMouseEnter}
                onMouseLeave={this.onNavMouseLeave}
                className={this.getElementClass(
                  'nav-link',
                  navEntered ? 'dim' : null
                )}>
                Case Studies
              </NavLink>
              <NavLink
                to="/about"
                onMouseEnter={this.onNavMouseEnter}
                onMouseLeave={this.onNavMouseLeave}
                className={this.getElementClass(
                  'nav-link',
                  navEntered ? 'dim' : null
                )}>
                About
              </NavLink>
              <NavLink
                to="/contact"
                onMouseEnter={this.onNavMouseEnter}
                onMouseLeave={this.onNavMouseLeave}
                className={this.getElementClass(
                  'nav-link',
                  navEntered ? 'dim' : null
                )}>
                Contact
              </NavLink>
            </div>
            <div className={this.getElementClass('nav-bottom')}>
              <div className={this.getElementClass('nav-social-links')}>
                <ExternalLink
                  className={this.getElementClass('nav-social-link')}
                  href="https://twitter.com/rekallai">
                  <Icon name="twitter" width={26} height={26} />
                </ExternalLink>
                <ExternalLink
                  className={this.getElementClass('nav-social-link')}
                  href="https://www.facebook.com/pg/rekallai/about/">
                  <Icon name="facebook" width={26} height={26} />
                </ExternalLink>
                <ExternalLink
                  className={this.getElementClass('nav-social-link')}
                  href="https://www.linkedin.com/company/rekall-ai">
                  <Icon name="linkedin" width={26} height={26} />
                </ExternalLink>
              </div>
              <div className={this.getElementClass('nav-extra')}>
                <NavLink
                  to="/terms"
                  className={this.getElementClass('nav-extra-link')}>
                  Terms & Conditions
                </NavLink>
                <NavLink
                  to="/privacy"
                  className={this.getElementClass('nav-extra-link')}>
                  Privacy Policy
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={this.closePanel}
          className={this.getElementClass(
            'nav-panel-dimmer',
            panelActive ? 'active' : null
          )}
        />
      </header>
    );
  }
}
