import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon } from 'semantic';

import { Screen } from 'public/helpers';
import {
  Swing,
  Spacer,
  FlipCard,
  ScrollArrow,
  Caption,
  Clock,
  CaseStudy,
  CardSection,
  Headquarters,
  Marquee,
  Equalizer,
  CustomCursor,
  AdaptiveImage,
} from 'public/components';
import { Layout, ScrollWaypoint } from 'common/components';

import { IMAGES, AUDIO } from 'public/assets';

import './home.less';

export default class Home extends Screen {
  title = 'Enterprise Software Consultants';
  description =
    'Rekall is a team of elite engineers and data scientists. We challenge organizations large and small to ignore the impossible and seize upon market-defining business opportunities.';

  constructor(props) {
    super(props);
    this.state = {
      audioProgress: 0,
      audioReady: false,
      audioPlaying: false,
      audioCursorActive: false,
    };
    this.audioRef = React.createRef();
    this.clocksRef = React.createRef();
  }

  componentDidMount() {
    this.setClocksMiddle();
    this.audioRef.current.addEventListener('ended', this.onAudioEnded);
    this.audioRef.current.addEventListener('timeupdate', this.onAudioUpdate);
    this.audioRef.current.addEventListener(
      'canplaythrough',
      this.onAudioCanPlayThrough
    );
  }

  componentWillUnmount() {
    this.audioRef.current.removeEventListener('ended', this.onAudioEnded);
    this.audioRef.current.removeEventListener('timeupdate', this.onAudioUpdate);
    this.audioRef.current.removeEventListener(
      'canplaythrough',
      this.onAudioCanPlayThrough
    );
  }

  onPlayClick = async () => {
    const audio = this.audioRef.current;
    await audio.play();
    this.setState({
      audioPlaying: true,
    });
  };

  onPauseClick = () => {
    const audio = this.audioRef.current;
    audio.pause();
    this.setState({
      audioPlaying: false,
    });
  };

  onAudioEnded = () => {
    this.setState({
      audioPlaying: false,
    });
  };

  onAudioUpdate = (evt) => {
    const audio = evt.target;
    const audioProgress = audio.currentTime / audio.duration;
    this.setState({
      audioProgress,
    });
  };

  onAudioCanPlayThrough = () => {
    this.setState({
      audioReady: true,
    });
  };

  onQuoteClick = () => {
    if (this.state.audioPlaying) {
      this.onPauseClick();
    } else {
      this.onPlayClick();
    }
  };

  onQuoteMouseEnter = () => {
    this.setState({
      audioCursorActive: true,
    });
  };

  onQuoteMouseLeave = () => {
    this.setState({
      audioCursorActive: false,
    });
  };

  setClocksMiddle() {
    setTimeout(() => {
      const el = this.clocksRef.current;
      if (el.scrollWidth > el.clientWidth) {
        el.scrollLeft = el.scrollWidth / 2 - window.innerWidth / 2;
      }
    }, 500);
  }

  renderBody() {
    return (
      <React.Fragment>
        <ScrollArrow
          sections={[
            {
              id: 'line-1',
            },
            {
              id: 'line-2',
            },
            {
              id: 'line-3',
            },
            {
              id: 'profile',
              top: true,
            },
          ]}
        />
        <div {...this.getProps()}>
          <Container>
            <div className={this.getElementClass('hero')}>
              <Swing top>
                <h2 className="indent" id="line-1">
                  Rekall is <em>a team of</em>
                </h2>
              </Swing>
              <Swing top>
                <h2>
                  <em>elite</em> engineers and
                </h2>
              </Swing>
              <Swing top>
                <h2>data scientists.</h2>
              </Swing>
              <Swing top>
                <h2 className="indent" id="line-2">
                  From strategy to execution,
                </h2>
              </Swing>
              <Spacer size="m" />
              <Swing>
                <h3 className="indent" id="line-3">
                  We challenge businesses (<em>large and small</em>) to ignore
                  the impossible and seize upon market-defining business
                  opportunities.
                </h3>
              </Swing>
              <Spacer size="l" />
            </div>
          </Container>
          <div className={this.getElementClass('container')}>
            <div className="light">
              <div className={this.getElementClass('profile')}>
                <Marquee id="profile" sticky spaced>
                  What We <em>Believe In –</em>
                </Marquee>
                <ScrollWaypoint>
                  <Layout horizontal padded stackable>
                    <Layout.Group>
                      <Swing>
                        <div className={this.getElementClass('profile-image')}>
                          <AdaptiveImage
                            src={IMAGES['team']['kirk']}
                            sizes="307px"
                          />
                          <img src={IMAGES['home-profile']} />
                          {this.state.audioReady && (
                            <React.Fragment>
                              {this.state.audioPlaying ? (
                                <div
                                  onClick={this.onPauseClick}
                                  className={this.getElementClass('pause')}>
                                  <span
                                    className={this.getElementClass(
                                      'pause-text'
                                    )}>
                                    pause
                                  </span>
                                  <span
                                    className={this.getElementClass(
                                      'pause-button'
                                    )}>
                                    <Equalizer />
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={this.onPlayClick}
                                  className={this.getElementClass('play')}>
                                  <span
                                    className={this.getElementClass(
                                      'play-text'
                                    )}>
                                    play audio
                                  </span>
                                  <span
                                    className={this.getElementClass(
                                      'play-button'
                                    )}>
                                    <Icon name="audio" size="tiny" />
                                  </span>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                        <audio ref={this.audioRef} preload="true">
                          <source src={AUDIO['kirk-mp4']} type="audio/mp4" />
                          <source src={AUDIO['kirk-ogg']} type="audio/ogg" />
                          <source src={AUDIO['kirk-mp3']} type="audio/mpeg" />
                        </audio>
                        <div className={this.getElementClass('profile-title')}>
                          Kirk McMurray
                        </div>
                        <div className={this.getElementClass('profile-body')}>
                          Cofounder & CEO
                        </div>
                      </Swing>
                    </Layout.Group>
                    <blockquote className={this.getElementClass('quote')}>
                      <div>
                        <Swing>
                          <div className={this.getElementClass('quote-prefix')}>
                            “
                          </div>
                        </Swing>
                        <Swing className={this.getElementClass('quote-text')}>
                          <div
                            className={this.getElementClass('quote-cursor')}
                            onClick={this.onQuoteClick}
                            onMouseEnter={this.onQuoteMouseEnter}
                            onMouseLeave={this.onQuoteMouseLeave}>
                            <Caption progress={this.state.audioProgress}>
                              People throw the terms AI and ML around
                              carelessly.||| The truth is there is no silver
                              bullet. At Rekall, we architect data-driven
                              platforms|| from the ground up| that are AI
                              ready.| In short, we build smarter software.|||||
                            </Caption>
                            <CustomCursor
                              text={this.state.audioPlaying ? 'pause' : 'play'}
                              active={
                                this.state.audioReady &&
                                this.state.audioCursorActive
                              }
                            />
                          </div>
                        </Swing>
                      </div>
                    </blockquote>
                  </Layout>
                </ScrollWaypoint>
                <Spacer size="xl" />
              </div>
            </div>
            <div className="dark">
              <CardSection title="Why Us" background>
                <FlipCard
                  number="001"
                  front="Proven expertise from startup to enterprise"
                  back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
                />
                <FlipCard
                  number="002"
                  front="Highly differentiated Solutions"
                  back="We eschew standard playbooks or recycled tactics, and create frameworks and solutions that are customized to specific business needs and challenges."
                />
                <FlipCard
                  number="003"
                  front="Measurable Impact"
                  back="Our work has an ongoing quantifiable impact linked directly to our client’s business KPIs.We spend time and money like it were our own."
                />
                <FlipCard
                  number="004"
                  front="Modular + Future-Proof Development"
                  back="We develop our solutions based on needs and timeline, not complete overhauls. We engage in stages of discreet, calculated work with direct results, and flexible development that allows for modifications and integration in the future."
                />
              </CardSection>
              <Container>
                <Swing>
                  <h2 className={this.getElementClass('case-studies-heading')}>
                    Case <em>studies</em>
                  </h2>
                </Swing>
              </Container>
              <Spacer size="m" />
              <Swing>
                <CaseStudy
                  slug="nuelife"
                  title="nue.life"
                  subtitle="Technology powering new perspectives"
                  tagline={
                    <React.Fragment>
                      An innovative people first approach to caring for{' '}
                      <em>mental health</em>
                    </React.Fragment>
                  }
                />
              </Swing>
              <Spacer size="l" />
              <Swing>
                <CaseStudy
                  slug="housebar"
                  title="HouseBar"
                  subtitle="Technology that brings cheers"
                  tagline={
                    <React.Fragment>
                      A reinvention of the liquor store that combines the best
                      of <em>retail and mobile technology</em>
                    </React.Fragment>
                  }
                />
              </Swing>
              <Spacer size="l" />
            </div>
            <div className="light">
              <Marquee sticky spaced>
                Meet the <em>team –</em>
              </Marquee>
              <Container className="small">
                <Swing>
                  <h3 className="indent">
                    We’re a <em>tried-and-true</em> team of senior operators who
                    value pragmatism and outcomes above all else.
                  </h3>
                </Swing>
                <Spacer size="s" />
                <Swing>
                  <h3>
                    We are, <em>by nature</em>, technology agnostic and highly
                    pragmatic.
                  </h3>
                </Swing>
                <Link
                  to="/about"
                  className={this.getElementClass('mobile-cta')}>
                  <span className={this.getElementClass('mobile-cta-icon')}>
                    <Icon name="arrow-diagonal" size="tiny" />
                  </span>
                  about Rekall
                </Link>
              </Container>
              <Spacer size="l" />
            </div>
            <div className="dark">
              <Headquarters />
              <Spacer size="l" />
              <div
                ref={this.clocksRef}
                className={this.getElementClass('clocks')}>
                <Clock timezone="America/New_York" />
                <Clock timezone="Europe/Amsterdam" />
                <Clock timezone="Asia/Tokyo" />
              </div>
              <Spacer size="l" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
