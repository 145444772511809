const {
  ENV_NAME,
  SENTRY_DSN,
  GA_TRACKING_ID,
  GCLOUD_VIDEOS_BUCKET,
  APP_NAME,
  APP_URL,
} = window.__ENV__;

export {
  ENV_NAME,
  SENTRY_DSN,
  GA_TRACKING_ID,
  GCLOUD_VIDEOS_BUCKET,
  APP_NAME,
  APP_URL,
};
