import { throttle } from 'lodash';
import { Container } from 'semantic';

import { Screen } from 'public/helpers';
import {
  Swing,
  Clock,
  ScrollArrow,
  Spacer,
  Marquee,
  Headquarters,
} from 'public/components';

import './grow.less';

import maxsipLogo from 'public/assets/grow/logos/maxsip.png';
import rockawayLogo from 'public/assets/grow/logos/rockaway.png';
import openclearLogo from 'public/assets/grow/logos/openclear.png';
import lyfeLogo from 'public/assets/grow/logos/lyfe.png';
import smileDesignLogo from 'public/assets/grow/logos/smile-design.png';
import ecgLogo from 'public/assets/grow/logos/ecg.png';
import marvelLogo from 'public/assets/grow/logos/marvel.png';

import dna from 'public/assets/grow/dna.webp';

export default class Grow extends Screen {
  title = 'What We Do';
  description =
    'From c-suite strategy to on-the-ground implementation, Rekall operates across your organization to define and build data-centric solutions leveraging the latest technology to solve real business problems. ';

  constructor(props) {
    super(props);
    this.state = {
      logoOffset: 0,
    };
  }

  onBrandsEntered = () => {
    if (window.innerWidth > 768) {
      this.scrollOrigin = window.scrollY;
      window.addEventListener('scroll', this.onBrandsScroll);
    }
  };

  onBrandsLeft = () => {
    window.removeEventListener('scroll', this.onBrandsScroll);
  };

  onBrandsScroll = throttle(() => {
    const logoOffset = Math.max(0, window.scrollY - this.scrollOrigin) * -0.4;
    this.setState({
      logoOffset,
    });
  }, 200);

  getLogoStyles() {
    if (window.innerWidth > 768) {
      const { logoOffset } = this.state;
      const transform = `translateX(${logoOffset}px)`;
      return {
        transform,
        WebkitTransform: transform,
      };
    }
  }

  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          sections={[
            {
              id: 'line-1',
            },
            {
              id: 'line-2',
            },
            {
              id: 'intro',
              top: true,
            },
          ]}
        />
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing>
              <h2 className="indent" id="line-1">
                Beyond One-Size-Fits-All: Dive into <em>Customized Growth</em>{' '}
                with Rekall Grow
              </h2>
            </Swing>
            <Spacer size="m" />
            <Swing>
              <h3 className="indent" id="line-2">
                REKALL Grow is a boutique agency executing 'full funnel' growth
                marketing for startups and mid market customers, focusing on
                direct response and achieving the best ROI for our partners.
              </h3>
            </Swing>
            <Spacer size="xl" />
          </div>
        </Container>
        <div id="intro" className={this.getElementClass('container')}>
          <div className="light">
            <Marquee sticky spaced>
              <img src={maxsipLogo} height={100} />
              <img src={rockawayLogo} height={150} />
              <img src={openclearLogo} height={90} />
              <img
                src={lyfeLogo}
                height={100}
                style={{
                  marginTop: '10px',
                  marginBottom: '-10px',
                }}
              />
              <img src={smileDesignLogo} height={100} />
              <img src={ecgLogo} height={100} />
              <img
                src={marvelLogo}
                height={180}
                style={{
                  marginTop: '40px',
                  marginBottom: '-40px',
                }}
              />
            </Marquee>
            <Spacer size="l" />
          </div>
          <div className="dark">
            <Spacer size="l" />
            <div className={this.getElementClass('cards')}>
              <div className={this.getElementClass('card')}>
                <div className={this.getElementClass('card-number')}>01.</div>
                <h3>Paid Social</h3>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                  <li>Trend Analysis </li>
                  <li>Personas & Messaging</li>
                  <li>Creative Strategy & Production</li>
                  <li>Design & Storyboarding</li>
                  <li>Creator Led Content </li>
                  <li>Ad Creative Intelligence </li>
                  <li>Attribution (AppsFlyer)</li>
                  <li>Reporting (Looker Studio)</li>
                </ul>
              </div>
              <div className={this.getElementClass('card')}>
                <div className={this.getElementClass('card-number')}>02.</div>
                <h3>Google Platform / SEM</h3>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                  <li>Strategy and planning </li>
                  <li>Competitive Benchmarking</li>
                  <li>ROI optimization</li>
                  <li>Account Health Check </li>
                  <li>Full Funnel Conversion Tracking</li>
                  <li>Prospecting and Retargeting across GDN and YouTube</li>
                  <li>Reporting (Google Analytics, Auction Insights)</li>
                </ul>
              </div>
              <div className={this.getElementClass('card')}>
                <div className={this.getElementClass('card-number')}>03.</div>
                <h3>Email & SMS Marketing</h3>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                  <li>Email & SMS Marketing Strategy </li>
                  <li>Design & Copy </li>
                  <li>Implementation</li>
                  <li>A/B Testing and Analysis</li>
                  <li>Retargeting and Prospecting</li>
                </ul>
              </div>
            </div>
            <Spacer size="l" />
          </div>
          <div className="dark">
            <Container>
              <h3 className={this.getElementClass('dna-header')}>
                AI <em>Powered</em> Tool
              </h3>
              <p>
                Our Ad Creative Intelligence tool helps our clients to save ad
                budget and time.
              </p>
              <Spacer size="m" />
              <img src={dna} className={this.getElementClass('dna-image')} />
              <Spacer size="l" />
              <div className={this.getElementClass('dna-stats')}>
                <div className={this.getElementClass('dna-stat')}>
                  LOWER CAC
                  <div className={this.getElementClass('dna-stat-number')}>
                    40%
                  </div>
                  Reduction on average
                </div>
                <div className={this.getElementClass('dna-stat')}>
                  TEST FASTER
                  <div className={this.getElementClass('dna-stat-number')}>
                    10x
                  </div>
                  Faster than hiring creatives in-house
                </div>
                <div className={this.getElementClass('dna-stat')}>
                  SHIP MORE
                  <div className={this.getElementClass('dna-stat-number')}>
                    3x
                  </div>
                  Faster turnaround for ad creatives
                </div>
                <div className={this.getElementClass('dna-stat')}>
                  REDUCE COSTS
                  <div className={this.getElementClass('dna-stat-number')}>
                    25%
                  </div>
                  Reduction in average cost per ad creative
                </div>
              </div>
              <Spacer size="xl" />
            </Container>
          </div>
          <div className="dark">
            <Headquarters />
            <Spacer size="l" />
            <div
              ref={this.clocksRef}
              className={this.getElementClass('clocks')}>
              <Clock timezone="America/New_York" />
              <Clock timezone="Europe/Amsterdam" />
              <Clock timezone="Asia/Tokyo" />
            </div>
            <Spacer size="l" />
          </div>
        </div>
      </div>
    );
  }
}
