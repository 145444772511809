import PropTypes from 'prop-types';

import { Icon } from 'semantic';

import { Component } from 'common/helpers';

import './arrow-button.less';

export default class ArrowButton extends Component {
  getModifiers() {
    const { down, left, right } = this.props;
    return [down ? 'down' : null, left ? 'left' : null, right ? 'right' : null];
  }

  getSize() {
    let { width, height } = this.props;
    if (window.innerWidth < 768) {
      width *= 0.8;
      height *= 0.8;
    }
    return {
      width,
      height,
    };
  }

  render() {
    const { dark } = this.props;
    const { width, height } = this.getSize();
    return (
      <div {...this.getProps(true)}>
        <Icon
          name="arrow-inset"
          className={this.getElementClass('stroke', dark ? 'dark' : null)}
          width={width}
          height={height}
        />
        <Icon
          name="arrow-inset"
          className={this.getElementClass('fill', dark ? 'dark' : null)}
          width={width}
          height={height}
        />
      </div>
    );
  }
}

ArrowButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  down: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  dark: PropTypes.bool,
};

ArrowButton.defaultProps = {
  width: 145,
  height: 145,
  down: false,
  left: false,
  right: false,
  dark: false,
};
