import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon } from 'semantic';
import { Swipeable } from 'react-swipeable';

import { Screen } from 'public/helpers';
import {
  Spacer,
  Swing,
  AdaptiveImage,
  ScrollContainer,
  ScrollArrow,
  Headquarters,
  Marquee,
} from 'public/components';

import { IMAGES } from 'public/assets';

import TeamMember from './TeamMember';

import TEAM from './team.json';

import './about.less';

export default class About extends Screen {
  title = 'About Us';
  description =
    'Founded in 2017, Rekall is a team of elite engineers and data scientists. We build smarter software to challenge organizations to ignore the impossible and seize upon market-defining business opportunities.';

  constructor(props) {
    super(props);
    this.state = {
      counter: 1,
      expanded: null,
    };
  }

  onSwipeLeft = () => {
    this.setState({
      counter: Math.min(TEAM.length, this.state.counter + 1),
    });
  };

  onSwipeRight = () => {
    this.setState({
      counter: Math.max(1, this.state.counter - 1),
    });
  };

  setExpanded = (member) => {
    this.setState({
      expanded: this.state.expanded === member ? null : member,
    });
  };

  onAreMount = (el) => {
    this.alignLeft('are', el);
  };

  onNotMount = (el) => {
    this.alignLeft('not', el);
  };

  alignLeft(name, scrollEl) {
    setTimeout(() => {
      const offset =
        this.getOffset(`${name}-align`) - this.getOffset(`${name}-container`);
      scrollEl.scrollLeft = Math.round(offset);
    }, 200);
  }

  getOffset(id) {
    return document.getElementById(id).getBoundingClientRect().left;
  }

  getTeamListStyles() {
    const { counter } = this.state;
    const transform = `translateX(-${(counter - 1) * 80}vw)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          sections={[
            {
              id: 'team',
              top: true,
            },
          ]}
        />
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing top>
              <h2>
                Working with Rekall is like bringing in your own black ops
                development team.
              </h2>
            </Swing>
            <Spacer size="xl" />
          </div>
        </Container>
        <div className={this.getElementClass('container')}>
          <div className="light" id="team">
            <Container>
              <div className={this.getElementClass('team-header')}>
                <p className={this.getElementClass('team-title')}>
                  Rekall leadership team
                </p>
                <div className={this.getElementClass('team-counter')}>
                  <div className={this.getElementClass('team-counter-top')}>
                    {this.state.counter}
                  </div>
                  <div
                    className={this.getElementClass('team-counter-divider')}
                  />
                  <div className={this.getElementClass('team-counter-bottom')}>
                    <em>{TEAM.length}</em>
                  </div>
                </div>
              </div>
              <Swipeable
                onSwipedLeft={this.onSwipeLeft}
                onSwipedRight={this.onSwipeRight}>
                <div
                  className={this.getElementClass('team-list')}
                  style={this.getTeamListStyles()}>
                  <div className={this.getElementClass('divider')} />
                  {TEAM.map((member) => {
                    const { name, title, header, description, image } = member;
                    return (
                      <React.Fragment key={name}>
                        <TeamMember
                          name={name}
                          title={title}
                          header={header}
                          description={description}
                          image={IMAGES['team'][image]}
                          expanded={this.state.expanded === member}
                          onExpandClick={() => this.setExpanded(member)}
                        />
                        <div className={this.getElementClass('divider')} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Swipeable>
              <Spacer size="m" />
            </Container>
          </div>
          <div className="dark">
            <Marquee spaced>
              <em>Background –</em>
            </Marquee>
            <Container>
              <div className={this.getElementClass('background-1')}>
                <h3>
                  Rekall began in 2017 as a{' '}
                  <em>product-focused machine learning startup</em> lead by a
                  senior team of coveted engineers, data scientists and product
                  leaders. With a global headcount of 20+ senior engineers and
                  data scientists.
                </h3>
              </div>
            </Container>
            <Container className="small">
              <Spacer size="m" />
              <hr />
              <Spacer size="m" />
              <div className={this.getElementClass('background-2')}>
                <div>
                  Despite an impressive machine learning product, clients were
                  more drawn to the elite team behind it -- founders with a long
                  history of building AI and data-driven platforms, that have
                  launched hundreds of enterprise digital, ecommerce, and mobile
                  projects in their careers.
                </div>
                <div>
                  <h3>Today,</h3>
                  <Spacer size="s" />
                  <p>
                    Rekall is a global team that serves organizations that seek
                    to dominate their respective markets with
                    technology-forward, data-driven solutions.
                  </p>
                </div>
              </div>
              <Spacer size="l" />
            </Container>
          </div>
          <div className="light">
            <Spacer size="m" />
            <ScrollContainer
              id="are-container"
              dark
              title="We are"
              largeTitle
              align
              onMount={this.onAreMount}>
              <div className={this.getElementClass('label', 'white')}>
                <em>industry veterans</em>
              </div>
              <div className={this.getElementClass('label')} id="are-align">
                optimists
              </div>
              <div className={this.getElementClass('label', 'white')}>
                <em>strategists</em>
              </div>
              <div className={this.getElementClass('label', 'white')}>
                <b>humanists</b>
              </div>
              <div className={this.getElementClass('label', 'white')}>
                <em>empaths</em>
              </div>
            </ScrollContainer>
            <Spacer size="m" />
          </div>
          <div className="dark">
            <Spacer size="m" />
            <ScrollContainer
              align
              id="not-container"
              title="We're not"
              largeTitle
              onMount={this.onNotMount}>
              <div className={this.getElementClass('label', 'strike')}>
                <em>egoists</em>
              </div>
              <div
                className={this.getElementClass('label', 'strike', 'white')}
                id="not-align">
                nostalgists
              </div>
              <div className={this.getElementClass('label', 'strike')}>
                <em>alchemists</em>
              </div>
              <div className={this.getElementClass('label', 'strike')}>
                <b>alarmists</b>
              </div>
            </ScrollContainer>
          </div>
          <div className="light">
            <div className={this.getElementClass('quote')}>
              <AdaptiveImage
                src={IMAGES['light-background']}
                className={this.getElementClass('quote-background')}
              />
              <Container>
                <Spacer size="l" />
                <div className={this.getElementClass('quote-container')}>
                  <div>
                    <div className={this.getElementClass('quote-roi')}>
                      Our focus is on ROI and action.
                    </div>
                    <Link
                      to="/services"
                      className={this.getElementClass('quote-services-link')}>
                      <span
                        className={this.getElementClass('quote-services-icon')}>
                        <Icon name="arrow-diagonal" size="tiny" />
                      </span>
                      <span
                        className={this.getElementClass('quote-services-text')}>
                        our services
                      </span>
                    </Link>
                  </div>
                  <div>
                    <blockquote className={this.getElementClass('quote-text')}>
                      <h2 className="indent">
                        We engage on projects from{' '}
                        <em>ideation and business definition</em> to product
                        execution and deployment.
                      </h2>
                    </blockquote>
                  </div>
                </div>
                <Spacer size="l" />
              </Container>
            </div>
          </div>
          <div className="dark">
            <Headquarters />
          </div>
        </div>
      </div>
    );
  }
}
