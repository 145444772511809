
let hasTouch;
try {
  document.createEvent('TouchEvent');
  document.documentElement.classList.add('touch');
  hasTouch = true;
} catch(err) {
  hasTouch = false;
}

export default hasTouch;
