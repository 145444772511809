import { Transition, ScrollWaypoint } from 'common/components';
import { Component } from 'common/helpers';

import { FeatureDebugger } from 'utils/debug';

import { ScrollFade } from '../ScrollFade';

import './swing.less';

const GLOBAL_DELAY = 500;

export default class Swing extends Component {
  static queued = 0;

  static queue() {
    this.queued++;
    return new Promise((resolve) => {
      setTimeout(
        () => {
          this.queued--;
          resolve();
        },
        GLOBAL_DELAY * (this.queued - 1)
      );
    });
  }

  componentDidMount() {
    FeatureDebugger.subscribe('swing', this.onFeatureChange);
  }

  componentWillUnmount() {
    FeatureDebugger.unsubscribe('swing', this.onFeatureChange);
  }

  onFeatureChange = () => {
    this.forceUpdate();
  };

  state = {
    active: false,
  };

  onEnter = async () => {
    await Swing.queue();
    this.setState({
      active: true,
    });
  };

  getModifiers() {
    return [!FeatureDebugger.isEnabled('swing') ? 'disabled' : null];
  }

  render() {
    const { top } = this.props;
    const { active } = this.state;
    return (
      <ScrollWaypoint onEnter={this.onEnter} threshold={top ? 0.1 : 0.5}>
        <Transition in={active}>
          <div {...this.getProps()}>
            <ScrollFade>{this.props.children}</ScrollFade>
          </div>
        </Transition>
      </ScrollWaypoint>
    );
  }
}
