import { Component } from 'common/helpers';
import { Transition } from 'common/components';

import './flip-card.less';

import cardFront from './card-front.jpg';
import cardBack from './card-back.jpg';

export default class FlipCard extends Component {
  getBackgroundImage(url) {
    return {
      backgroundImage: `url(${url})`,
    };
  }

  render() {
    const { number, front, back, flipped } = this.props;
    return (
      <div
        onClick={this.props.onClick}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        {...this.getProps()}>
        <Transition in={flipped}>
          <div className={this.getElementClass('flipper')}>
            <div
              className={this.getElementClass('front')}
              style={this.getBackgroundImage(cardFront)}>
              <div className={this.getElementClass('front-number')}>
                {number}
              </div>
              <div className={this.getElementClass('front-text')}>{front}</div>
            </div>
            <div
              className={this.getElementClass('back')}
              style={this.getBackgroundImage(cardBack)}>
              <div className={this.getElementClass('back-number')}>
                {number}
              </div>
              <div className={this.getElementClass('back-text')}>{back}</div>
            </div>
          </div>
        </Transition>
      </div>
    );
  }
}
