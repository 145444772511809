import React from 'react';

import { Component } from 'common/helpers';

import wheel from 'public/assets/3d/wheel.gltf';

import { mapLinear } from 'utils/math';

import { FeatureDebugger } from 'utils/debug';

import SceneController from './SceneController';


import './scene.less';

const RING_INIT_VELOCITY = -0.003;

export default class Scene extends Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.mouseOriginX = null;
  }

  componentDidMount() {
    this.sceneController = new SceneController(this.ref.current);
    this.load();
    FeatureDebugger.subscribe('3D', this.onFeatureChange);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('deviceorientation', this.onOrientationChange, true);
    clearTimeout(this.timer);
    FeatureDebugger.unsubscribe(this.onFeatureChange);
  }

  onFeatureChange = (val) => {
    this.sceneController.setEnabled(val);
  }

  onOrientationChange = (evt) => {
    if (this.originGamma == null) {
      this.originGamma = evt.gamma;
    } else {
      const offset = mapLinear(evt.gamma - this.originGamma, 0, 360, -1, 1);
      this.sceneController.setCameraRotationOffset(offset);
    }
  }

  onMouseMove = (evt) => {
    if (this.mouseOriginX === null) {
      this.mouseOriginX = evt.clientX;
    } else {
      const { innerWidth } = window;
      const dx = evt.clientX - this.mouseOriginX;
      const offset = mapLinear(dx, -innerWidth, innerWidth, -1, 1);
      this.sceneController.setCameraRotationOffset(offset);
    }
  }

  getModifiers() {
    const { fixed } = this.props;
    return [
      fixed ? 'fixed' : null,
    ];
  }

  async load() {
    await this.sceneController.loadGLTF(wheel);
    this.timer = setTimeout(() => {
      this.sceneController.setRingVelocity(RING_INIT_VELOCITY);
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('deviceorientation', this.onOrientationChange, true);
    }, 500);
  }

  render() {
    return <div ref={this.ref} {...this.getProps()} />;
  }

}
