import { Icon } from 'semantic';

import { Component } from 'common/helpers';
import { AdaptiveImage } from 'public/components';

import './team-member.less';

export default class TeamMember extends Component {
  getModifiers() {
    const { expanded } = this.props;
    return [expanded ? 'expanded' : null];
  }

  onExpandClick = () => {
    this.props.onExpandClick(this.props.id);
  };

  render() {
    const { expanded } = this.props;
    return (
      <div {...this.getProps()}>
        <AdaptiveImage
          src={this.props.image}
          className={this.getElementClass('image')}
          sizes="293px"
        />
        <div className={this.getElementClass('top')}>
          <div className={this.getElementClass('left')}>
            <div className={this.getElementClass('name')}>
              {this.props.name}
            </div>
            <div className={this.getElementClass('title')}>
              {this.props.title}
            </div>
          </div>
          <div
            onClick={this.onExpandClick}
            className={this.getElementClass('button')}>
            <Icon size="small" name={expanded ? 'minus' : 'plus'} />
          </div>
        </div>
        {expanded && (
          <div className={this.getElementClass('body')}>
            <span className={this.getElementClass('header')}>
              {this.props.header}
            </span>
            <span className={this.getElementClass('description')}>
              {this.props.description}
            </span>
          </div>
        )}
      </div>
    );
  }
}
