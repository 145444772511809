import React from 'react';
import { createPortal } from 'react-dom';

import { Component } from 'common/helpers';

import { hasTouch } from 'utils/touch';
import { FeatureDebugger } from 'utils/debug';

import './custom-cursor.less';

export default class CustomCursor extends Component {

  ref = React.createRef();

  constructor(props) {
    super(props);
    this.lastX = null;
    this.lastY = null;
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.onMouseMove);
    FeatureDebugger.subscribe('cursor', this.onFeatureChange);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove);
    FeatureDebugger.unsubscribe('cursor', this.onFeatureChange);
  }

  componentDidUpdate(lastProps) {
    if (hasTouch || !this.ref.current) {
      return;
    }
    const { active } = this.props;
    if (active !== lastProps.active) {
      if (active && this.hasMousePosition()) {
        this.ref.current.style.display = 'block';
        this.update();
      } else {
        this.ref.current.style.display = 'none';
      }
    }
  }

  onMouseMove = (evt) => {
    this.lastX = evt.clientX;
    this.lastY = evt.clientY;
    if (this.props.active) {
      this.update();
    }
  }

  onFeatureChange = (enabled) => {
    if (enabled) {
      window.addEventListener('mousemove', this.onMouseMove);
    } else {
      window.removeEventListener('mousemove', this.onMouseMove);
    }
    this.forceUpdate();
  }

  update() {
    if (!this.ref.current) {
      return;
    }
    if (this.hasMousePosition()) {
      const el = this.ref.current;
      const transform = `translate(${this.lastX}px, ${this.lastY}px)`;
      el.style.transform = transform;
    }
  }

  hasMousePosition() {
    return this.lastX !== null && this.lastY !== null;
  }

  render() {
    if (hasTouch) {
      return null;
    }
    if (!FeatureDebugger.isEnabled('cursor')) {
      return null;
    }
    return createPortal(
      this.renderCursor(),
      document.body
    );
  }

  renderCursor() {
    const { down } = this.props;
    return (
      <div
        ref={this.ref}
        {...this.getProps()}>
        <div className={this.getElementClass('inner', down ? 'down' : null)}>
          {this.props.text}
        </div>
      </div>
    );
  }

}
