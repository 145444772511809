import { DateTime } from 'luxon';

import { Component } from 'common/helpers';

import './clock.less';

const DISPLAY = {
  'America/New_York': {
    city: 'New York',
    country: 'United States',
  },
  'Europe/Amsterdam': {
    city: 'Amsterdam',
    country: 'The Netherlands',
  },
  'Asia/Tokyo': {
    city: 'Tokyo',
    country: 'Japan',
  },
};

export default class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.initialized = false;
  }

  getTimeValues() {
    const { hour, minute, second } = DateTime.fromObject({
      zone: this.props.timezone,
    });
    return { hour, minute, second };
  }

  componentDidMount() {
    this.tick();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.initialied = false;
  }

  getCurrentState() {
    if (!this.initialized) {
      const values = this.getTimeValues();
      this.initialized = true;
      return values;
    } else {
      return this.state;
    }
  }

  tick = () => {
    // To keep the transition from reversing on 59 -> 0, we
    // need to always increment the values instead of using
    // the real times. In order to make sure this is accurate
    // it's important to initialize the values on the same tick
    // as mounting and setting state may be asynchronous.
    let { hour, minute, second } = this.getCurrentState();
    const {
      hour: nextHour,
      minute: nextMinute,
      second: nextSecond,
    } = this.getTimeValues();
    if (hour !== nextHour) {
      hour += 1;
    }
    if (minute !== nextMinute) {
      minute += 1;
    }
    if (second !== nextSecond) {
      second += 1;
    }
    this.setState({
      hour,
      minute,
      second,
    });
    this.timer = setTimeout(this.tick, 1000);
  };

  getHandStyle(name, deg) {
    const transform = `rotate(${deg}deg)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  render() {
    if (!this.initialized) {
      return null;
    }
    const { timezone } = this.props;
    const { hour, minute, second } = this.state;
    const display = DISPLAY[timezone];
    return (
      <div {...this.getProps()}>
        <div className={this.getElementClass('container')}>
          {this.renderHand('hour', hour * 30)}
          {this.renderHand('minute', minute * 6)}
          {this.renderHand('second', second * 6)}
          <div className={this.getElementClass('area')}>
            <div className={this.getElementClass('area-city')}>
              {display.city}
            </div>
            <div className={this.getElementClass('area-country')}>
              <em>{display.country}</em>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderHand(name, deg) {
    return (
      <div
        style={this.getHandStyle(name, deg)}
        className={this.getElementClass('hand', name)}
      />
    );
  }
}
