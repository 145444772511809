import React from 'react';

import { Component } from 'common/helpers';

import { FeatureDebugger } from 'utils/debug';

import './scroll-fade.less';

const SUPPORTED = !!window.IntersectionObserver;

export default class ScrollFade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entered: false
    };
    this.ref = React.createRef();
  }

  // Lifecycle

  componentDidMount() {
    this.createObserver();
    FeatureDebugger.subscribe('scroll-fade', this.onFeatureChange);
  }

  componentWillUnmount() {
    this.destroyObserver();
    FeatureDebugger.unsubscribe('scroll-fade', this.onFeatureChange);
  }

  // Observer

  createObserver() {
    if (SUPPORTED) {
      this.observer = new IntersectionObserver(this.onElementObserve, {
        rootMargin: '-100px 0px -100px 0px',
        threshold: 0.2,
      });
      this.observer.observe(this.ref.current);
    }
  }

  destroyObserver() {
    if (SUPPORTED) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  // Events

  onElementObserve = (entries) => {
    const [entry] = entries;
    const entered = entry.isIntersecting;
    if (entered !== this.state.entered) {
      this.setState({
        entered,
      });
    }
  }

  onFeatureChange = (enabled) => {
    if (enabled) {
      this.createObserver();
    } else {
      this.destroyObserver();
    }
    this.forceUpdate();
  }

  getModifiers() {
    const { entered } = this.state;
    return [
      entered ? 'entered' : null,
      !FeatureDebugger.isEnabled('scroll-fade') ? 'disabled' : null,
    ];
  }

  render() {
    return (
      <div ref={this.ref} {...this.getProps()}>
        {this.props.children}
      </div>
    );
  }

}
