import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import { SENTRY_DSN, ENV_NAME } from 'utils/env';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV_NAME,
  integrations: [new Integrations.ExtraErrorData()],
});
