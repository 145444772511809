import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic';

import {
  CaseStudy,
  Marquee,
  Swing,
  Spacer,
  CustomCursor,
} from 'public/components';
import { Screen } from 'public/helpers';

import './case-studies.less';

export default class CaseStudies extends Screen {
  title = 'Our Work';
  description =
    'Rekall has been creating next generation software for the innovation-focused enterprise and leading startups since 2017.';

  constructor(props) {
    super(props);
    this.state = {
      cursorActive: false,
    };
  }

  onAboutMouseEnter = () => {
    this.setState({
      cursorActive: true,
    });
  };

  onAboutMouseLeave = () => {
    this.setState({
      cursorActive: false,
    });
  };

  renderBody() {
    return (
      <div {...this.getProps()}>
        <div className={this.getElementClass('container')}>
          <div className="dark">
            <Container>
              <div className={this.getElementClass('hero')}>
                <Swing top>
                  <h2 className={this.getElementClass('case-studies-heading')}>
                    Case <em>studies</em>
                  </h2>
                </Swing>
                <Spacer size="s" />
                <Swing top>
                  <CaseStudy
                    slug="nuelife"
                    title="nue.life"
                    subtitle="Technology powering new perspectives"
                    tagline={
                      <React.Fragment>
                        An innovative people first approach to caring for{' '}
                        <em>mental health</em>
                      </React.Fragment>
                    }
                  />
                </Swing>
              </div>
              <hr />
              <Spacer size="m" />
              <Swing>
                <CaseStudy
                  slug="housebar"
                  title="HouseBar"
                  subtitle="Technology that brings cheers"
                  tagline={
                    <React.Fragment>
                      A reinvention of the liquor store that combines the best
                      of <em>retail and mobile technology</em>
                    </React.Fragment>
                  }
                />
              </Swing>
              <hr />
              <Spacer size="m" />
              <Swing>
                <CaseStudy
                  slug="litmus-health"
                  title="Litmus Health"
                  subtitle="Future-proof development"
                  tagline={
                    <React.Fragment>
                      A data science <em>platform-as-a-service</em>
                    </React.Fragment>
                  }
                />
              </Swing>
              <hr />
              <Spacer size="m" />
              <Swing>
                <CaseStudy
                  slug="e-flux"
                  title="E-Flux"
                  subtitle="API first development"
                  tagline={
                    <React.Fragment>
                      An open infrastructure for the future of{' '}
                      <em>eMobility</em> and <em>EV charging</em>
                    </React.Fragment>
                  }
                />
              </Swing>
              <hr />
              <Spacer size="m" />
              <Swing>
                <CaseStudy
                  slug="american-dream"
                  title="American Dream"
                  subtitle="Future tech in action"
                  tagline={
                    <React.Fragment>
                      A platform for the world’s largest experiential{' '}
                      <em>retail developer</em>
                    </React.Fragment>
                  }
                />
              </Swing>
              <Spacer size="l" />
            </Container>
            <div
              onMouseEnter={this.onAboutMouseEnter}
              onMouseLeave={this.onAboutMouseLeave}
              className={this.getElementClass('about')}>
              <Link className={this.getElementClass('about-link')} to="/about">
                <Marquee spaced stroke>
                  <em>About –</em>
                </Marquee>
                <Spacer size="m" />
              </Link>
              <CustomCursor text="About" active={this.state.cursorActive} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
