import { Component } from 'common/helpers';

export default class AdaptiveImage extends Component {
  getPlatform() {
    return window.innerWidth > 768 ? 'desktop' : 'mobile';
  }

  getSrc() {
    const { src } = this.props;
    if (src['desktop']) {
      return src[this.getPlatform()]['1x'];
    } else {
      return src['1x'];
    }
  }

  getSrcSet() {
    const { src } = this.props;
    if (src['desktop']) {
      return this.getSrcSetByPlatform(src);
    } else {
      return this.getSrcSetByRatio(src);
    }
  }

  getSrcSetByPlatform(src) {
    return ['desktop', 'mobile']
      .map((platform) => {
        return ['1x', '2x']
          .map((ratio) => {
            let w;
            if (platform === 'desktop') {
              w = ratio === '2x' ? 2880 : 1440;
            } else {
              w = ratio === '2x' ? 828 : 414;
            }
            return `${src[platform][ratio]} ${w}w`;
          })
          .join(',');
      })
      .join(',');
  }

  getSrcSetByRatio(src) {
    return ['1x', '2x'].map((ratio) => {
      return `${src[ratio]} ${ratio}`;
    });
  }

  render() {
    const { className, sizes } = this.props;
    return (
      <img
        className={className}
        src={this.getSrc()}
        srcSet={this.getSrcSet()}
        sizes={sizes || '(min-width: 1280px) 1280px, 100vw'}
      />
    );
  }
}
