import { Link } from 'react-router-dom';
import { Container } from 'semantic';

import { Component } from 'common/helpers';

import { IMAGES } from 'public/assets';

import { ScrollFade } from '../Flair';
import { AdaptiveImage } from '../AdaptiveImage';

import './case-study.less';

export default class CaseStudy extends Component {
  render() {
    const { slug, title, subtitle, tagline } = this.props;
    return (
      <div {...this.getProps()}>
        <Link
          className={this.getElementClass('link')}
          to={`/case-studies/${slug}`}>
          <div className={this.getElementClass('image-container')}>
            <AdaptiveImage
              className={this.getElementClass('image')}
              src={IMAGES['case-studies'][slug]['card']}
            />
          </div>
          <Container>
            <div className={this.getElementClass('header')}>
              <h3 className={this.getElementClass('title')}>{title}</h3>
              <h4 className={this.getElementClass('subtitle')}>{subtitle}</h4>
            </div>
            <ScrollFade>
              <h4 className={this.getElementClass('tagline')}>{tagline}</h4>
            </ScrollFade>
          </Container>
        </Link>
      </div>
    );
  }
}
