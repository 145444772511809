
import { default as alastair1x } from './alastair@1x.jpg';
import { default as alastair2x } from './alastair@2x.jpg';
import { default as andrew1x } from './andrew@1x.jpg';
import { default as andrew2x } from './andrew@2x.jpg';
import { default as coen1x } from './coen@1x.jpg';
import { default as coen2x } from './coen@2x.jpg';
import { default as dom1x } from './dom@1x.jpg';
import { default as dom2x } from './dom@2x.jpg';
import { default as drew1x } from './drew@1x.jpg';
import { default as drew2x } from './drew@2x.jpg';
import { default as feargal1x } from './feargal@1x.jpg';
import { default as feargal2x } from './feargal@2x.jpg';
import { default as freddy1x } from './freddy@1x.jpg';
import { default as freddy2x } from './freddy@2x.jpg';
import { default as jan1x } from './jan@1x.jpg';
import { default as jan2x } from './jan@2x.jpg';
import { default as kirk1x } from './kirk@1x.jpg';
import { default as kirk2x } from './kirk@2x.jpg';
import { default as mike1x } from './mike@1x.jpg';
import { default as mike2x } from './mike@2x.jpg';
import { default as ray1x } from './ray@1x.jpg';
import { default as ray2x } from './ray@2x.jpg';
import { default as sophian1x } from './sophian@1x.jpg';
import { default as sophian2x } from './sophian@2x.jpg';

export default {
  alastair: {
    '1x': alastair1x,
    '2x': alastair2x,
  },
  andrew: {
    '1x': andrew1x,
    '2x': andrew2x,
  },
  coen: {
    '1x': coen1x,
    '2x': coen2x,
  },
  dom: {
    '1x': dom1x,
    '2x': dom2x,
  },
  drew: {
    '1x': drew1x,
    '2x': drew2x,
  },
  feargal: {
    '1x': feargal1x,
    '2x': feargal2x,
  },
  freddy: {
    '1x': freddy1x,
    '2x': freddy2x,
  },
  jan: {
    '1x': jan1x,
    '2x': jan2x,
  },
  kirk: {
    '1x': kirk1x,
    '2x': kirk2x,
  },
  mike: {
    '1x': mike1x,
    '2x': mike2x,
  },
  ray: {
    '1x': ray1x,
    '2x': ray2x,
  },
  sophian: {
    '1x': sophian1x,
    '2x': sophian2x,
  },
};
