import { Container } from 'semantic';

import { IMAGES } from 'public/assets';
import {
  AdaptiveImage,
  Headquarters,
  Marquee,
  ScrollArrow,
  Spacer,
  Swing,
} from 'public/components';
import { Screen } from 'public/helpers';

import '../page.less';

export default class AmericanDream extends Screen {
  title = 'American Dream';
  description =
    "Rekall provided strategic consulting, product planning, experience design and engineering services for the entire American Dream product ecosystem, and delivered on-time and on-budget a platform for the world's largest experential retail developer";

  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          sections={[
            {
              id: 'overview',
              top: true,
            },
          ]}
        />
        <div className={this.getElementClass('background')}>
          <AdaptiveImage
            src={IMAGES['case-studies']['american-dream']['hero']}
          />
        </div>
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing>
              <h2 className="indent">
                A platform for the world’s largest experiential{' '}
                <em>retail developer</em>.
              </h2>
            </Swing>
            <Spacer size="l" />
          </div>
        </Container>
        <div className={this.getElementClass('container')}>
          <div className="light" id="overview">
            <Marquee sticky spaced>
              Project <em>Overview –</em>
            </Marquee>
            <Spacer size="xs" />
            <div className={this.getElementClass('overview-container')}>
              <Container className="small">
                <h3 className={this.getElementClass('left-offset')}>
                  <em>“Unrivaled.”</em> That’s how the team at Triple Five
                  Worldwide, owners of Mall of America and West Edmonton Mall,
                  describe their latest entertainment & retail destination,
                  American Dream.
                </h3>
                <Spacer size="s" />
                <h3 className={this.getElementClass('left-offset')}>
                  American Dream came to Apple’s Enterprise Design Lab with a
                  problem, a need to launch a state of the art technology
                  platform, a native iOS application, web application and later,
                  a native Android application, all in less than six months.
                </h3>
                <Spacer size="l" />
              </Container>
            </div>
          </div>
          <div className="dark">
            <Spacer size="l" />
            <Container>
              <div className={this.getElementClass('inset')}>
                <h3>
                  Building the <em>Dream</em>
                </h3>
                <p>
                  Apple and American Dream chose Rekall for our contemporary
                  technology stack, ability to shape American Dream’s product
                  and data strategy, and of course our speed and quality of
                  execution.
                </p>
              </div>
            </Container>
            <Spacer size="l" />
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['american-dream']['section1']}
              />
            </Container>
            <Container>
              <Spacer size="l" />
              <p>
                In addition to our world-class hospitality management technology
                platform built for a decade of innovation, Rekall provided
                strategic consulting, product planning, experience design and
                engineering services for the entire American Dream product
                ecosystem, and delivered on-time and on-budget.
              </p>
              <Spacer size="l" />
            </Container>
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['american-dream']['section2']}
              />
            </Container>
            <Spacer size="xl" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Key <em>Features –</em>
            </Marquee>
            <ul className={this.getElementClass('feature-list')}>
              <li>
                <h3>
                  Payments <em>– Mobile/web and POS</em>
                </h3>
              </li>
              <li>
                <h3>Directory, Maps and Wayfinding</h3>
              </li>
              <li>
                <h3>Messaging, Chat and CRM</h3>
              </li>
              <li>
                <h3>Transactional and triggered Email</h3>
              </li>
              <li>
                <h3>User management</h3>
              </li>
              <li>
                <h3>Content Management</h3>
              </li>
              <li>
                <h3>BI and Analytics</h3>
              </li>
            </ul>
            <Spacer size="l" />
          </div>
          {/*
          <div className="dark">
            <CardSection title="Future Tech in Action" background>
              <FlipCard
                number="001"
                front="Proven expertise from startup to enterprise"
                back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
              />
              <FlipCard
                number="002"
                front="Proven expertise from startup to enterprise"
                back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
              />
              <FlipCard
                number="003"
                front="Proven expertise from startup to enterprise"
                back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
              />
              <FlipCard
                number="004"
                front="Proven expertise from startup to enterprise"
                back="With decades of experience in founding and scaling lucrative startups, our elite team has seen it all, and done it faster and more efficiently than anyone."
              />
            </CardSection>
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Project <em>Outcomes –</em>
            </Marquee>
            <div className={this.getElementClass('outcomes-container')}>
              <Container>
                <h3>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sit
                  amet pellentesque orci. Sed sapien quam, lobortis ut tellus quis,
                  volutpat suscipit nisl. Nam commodo dolor lectus, at rhoncus
                  sapien porttitor quis.
                </h3>
              </Container>
              <Spacer size="s" />
              <div className={this.getElementClass('stats')}>
                <div className={this.getElementClass('stat')}>
                  <h5 className={this.getElementClass('stat-title')}>x3</h5>
                  <p>Lorem ipsum dolor sit amet elit.</p>
                </div>
                <div className={this.getElementClass('stat')}>
                  <h5 className={this.getElementClass('stat-title')}>15k</h5>
                  <p>Lorem ipsum dolor sit amet elit.</p>
                </div>
                <div className={this.getElementClass('stat')}>
                  <h5 className={this.getElementClass('stat-title')}>+5</h5>
                  <p>Lorem ipsum dolor sit amet elit.</p>
                </div>
              </div>
            </div>
            <Spacer size="l" />
          </div>
          */}
          <Headquarters background />
        </div>
      </div>
    );
  }
}
