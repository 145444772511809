import { Container } from 'semantic';

import { Screen } from 'public/helpers';
import { IMAGES } from 'public/assets';
import {
  Spacer,
  ScrollArrow,
  AdaptiveImage,
  Headquarters,
  Marquee,
  Swing,
} from 'public/components';

import '../page.less';

export default class Housebar extends Screen {
  title = 'HouseBar';
  description =
    ' A platform created to help users bring the festivities home by making it easy and enjoyable to find the best cocktail recipes and inspirations.';

  renderBody() {
    return (
      <div {...this.getProps()}>
        <ScrollArrow
          title="HouseBar"
          subtitle="Technology that brings cheers"
          sections={[
            {
              id: 'overview',
              top: true,
            },
          ]}
        />
        <div className={this.getElementClass('background')}>
          <AdaptiveImage src={IMAGES['case-studies']['housebar']['hero']} />
        </div>
        <Container>
          <div className={this.getElementClass('hero')}>
            <Swing>
              <h2 className="indent">
                A platform created to help users bring the festivities home by
                making it easy and enjoyable to find the best cocktail recipes
                and inspirations.
              </h2>
            </Swing>
            <Spacer size="l" />
          </div>
        </Container>
        <div className={this.getElementClass('container')}>
          <div className="light" id="overview">
            <Marquee sticky spaced>
              Project <em>Overview –</em>
            </Marquee>
            <Spacer size="xs" />
            <div className={this.getElementClass('overview-container')}>
              <Container className="small">
                <h3>
                  More than ever we need community. Our connections are so
                  important in today's world. Housebar makes it easier to
                  entertain at home by pairing the best selection of spirits,
                  mixers, and party essentials with effortless recipes and ideas
                  for every night of the week. Get back to living your life and
                  enjoying salutations and good tidings with your besties.
                </h3>
                <Spacer size="m" />
                <h3>
                  Rekall, along with industry experts, mixologists and product
                  curators from Housebar, have developed a platform that
                  streamlines your journey from inspiration to cocktail-in-hand
                  by meticulously selecting products and making it as convenient
                  as possible for you to create new unique cocktails right from
                  the comfort of your home.
                </h3>
                <Spacer size="s" />
                <h3></h3>
                <Spacer size="l" />
              </Container>
            </div>
          </div>
          <div className="dark">
            <Spacer size="m" />
            <Container>
              <div className={this.getElementClass('inset')}>
                <h3>Bringing the party home</h3>
                <p>
                  Housebar chose Rekall for our extensive experience in building
                  social and digital platforms, our modern approach to native
                  mobile development, as well as our expertise in creating
                  unique experiences that bridge the retail and e-commerce
                  worlds. Rekall's expertise integrating payment systems to
                  power both online and in-person purchases was critical in
                  creating a seamless experience that truly makes cocktail
                  creation effortless and fun.
                </p>
              </div>
            </Container>
            <Spacer size="m" />
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['housebar']['section1']}
              />
              <Spacer size="m" />
            </Container>
            <Container className="small">
              <Spacer size="m" />
              <p>
                Housebar now has a platform that powers the reinvention of the
                liquor store and allows users to quickly get to what's most
                important – enjoying great times, great drinks, and great food
                with friends and family.
              </p>
            </Container>
            <Spacer size="l" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Key <em>Features –</em>
            </Marquee>
            <ul className={this.getElementClass('feature-list')}>
              <li>
                <h3>One-step mobile cocktail ingredient purchasing</h3>
              </li>
              <li>
                <h3>Store-to-home on demand delivery</h3>
              </li>
              <li>
                <h3>Fully curated product inventory</h3>
              </li>
              <li>
                <h3>Data-driven personalized recommendations</h3>
              </li>
              <li>
                <h3>Step-by-step Cocktail creation guides</h3>
              </li>
              <li>
                <h3>Inspirational get-together event ideas</h3>
              </li>
            </ul>
            <Spacer size="l" />
          </div>
          <div className="dark">
            <Spacer size="l" />
            <Container className="large">
              <AdaptiveImage
                src={IMAGES['case-studies']['housebar']['section2']}
              />
            </Container>
            <Spacer size="l" />
          </div>
          <div className="light">
            <Marquee sticky spaced>
              Project <em>Outcomes –</em>
            </Marquee>
            <div className={this.getElementClass('outcomes-container')}>
              <Container>
                <div className={this.getElementClass('left-offset')}>
                  <h3>
                    By using Rekall, HouseBar is innovating in new ways with its
                    platform bringing users the fastest and most convenient way
                    to purchase any drink combination they can imagine.
                  </h3>
                </div>
                {/*
                <Spacer size="m" />
                <blockquote className={this.getElementClass('outcomes-quote')}>
                  <div>
                    <span
                      className={this.getElementClass('outcomes-quote-top')}>
                      What I get with Rekall is even better than a co-founder.
                    </span>{' '}
                    <span>
                      I get a co-founder, a CTO, a VP Eng, a mobile app dev, and
                      more!
                    </span>
                  </div>
                </blockquote>
                <Spacer size="s" />
                <div className={this.getElementClass('outcomes-quote-title')}>
                  Josh-Jones Dilsworth
                </div>
                <div
                  className={this.getElementClass('outcomes-quote-subtitle')}>
                  Litmus Health Co-Founder & Board Director
                </div>
                */}
              </Container>
            </div>
            <Spacer size="l" />
          </div>
          <Headquarters background />
        </div>
      </div>
    );
  }
}
