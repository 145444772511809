import { default as team } from './team';
import { default as logos } from './logos';
import { default as caseStudies } from './case-studies';

import { default as cardBackgroundDesktop1x } from './card-background-desktop@1x.jpg';
import { default as cardBackgroundDesktop2x } from './card-background-desktop@2x.jpg';
import { default as cardBackgroundMobile1x } from './card-background-mobile@1x.jpg';
import { default as cardBackgroundMobile2x } from './card-background-mobile@2x.jpg';

import { default as lightBackgroundDesktop1x } from './light-background-desktop@1x.jpg';
import { default as lightBackgroundDesktop2x } from './light-background-desktop@2x.jpg';
import { default as lightBackgroundMobile1x } from './light-background-mobile@1x.jpg';
import { default as lightBackgroundMobile2x } from './light-background-mobile@2x.jpg';

import { default as headquartersBackgroundDesktop1x } from './headquarters-background-desktop@1x.jpg';
import { default as headquartersBackgroundDesktop2x } from './headquarters-background-desktop@2x.jpg';
import { default as headquartersBackgroundMobile1x } from './headquarters-background-mobile@1x.jpg';
import { default as headquartersBackgroundMobile2x } from './headquarters-background-mobile@2x.jpg';

export default {
  team: team,
  logos: logos,
  'case-studies': caseStudies,
  'card-background': {
    desktop: {
      '1x': cardBackgroundDesktop1x,
      '2x': cardBackgroundDesktop2x,
    },
    mobile: {
      '1x': cardBackgroundMobile1x,
      '2x': cardBackgroundMobile2x,
    },
  },
  'light-background': {
    desktop: {
      '1x': lightBackgroundDesktop1x,
      '2x': lightBackgroundDesktop2x,
    },
    mobile: {
      '1x': lightBackgroundMobile1x,
      '2x': lightBackgroundMobile2x,
    },
  },
  'headquarters-background': {
    desktop: {
      '1x': headquartersBackgroundDesktop1x,
      '2x': headquartersBackgroundDesktop2x,
    },
    mobile: {
      '1x': headquartersBackgroundMobile1x,
      '2x': headquartersBackgroundMobile2x,
    },
  },
};
