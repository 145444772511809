import { Screen } from 'public/helpers';
import { Spacer, Headquarters } from 'public/components';

import './contact.less';

export default class Contact extends Screen {
  renderBody() {
    return (
      <div {...this.getProps()}>
        <Spacer size="l" />
        <div className={this.getElementClass('container')}>
          <div className="dark">
            <Headquarters />
          </div>
        </div>
      </div>
    );
  }
}
